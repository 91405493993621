<!-- @format -->

<template>
  <div class="home">
    <div class="info-card-list">
      <info-card
        :CardColor="'primary'"
        :CardTitle="'Receiveable'"
        :CardDetail="recable"
        class="small-card"
        :Loader="dashloader"
      ></info-card>
      <info-card
        :CardColor="'warning'"
        :CardTitle="'Payable'"
        :CardDetail="payable"
        class="small-card"
        :Loader="dashloader"
      ></info-card>
      <info-card
        :CardColor="'danger'"
        :CardTitle="'Expenses'"
        :CardDetail="expamt"
        class="small-card"
        :Loader="dashloader"
      ></info-card>
      <info-card
        :CardColor="'success'"
        :CardTitle="'Received'"
        :CardDetail="incamt"
        class="small-card"
        :Loader="dashloader"
      ></info-card>
      <info-card
        :CardColor="'default'"
        :CardTitle="'Balance'"
        :CardDetail="balamt"
        class="big-card"
        :Loader="dashloader"
      ></info-card>
    </div>
    <div class="mini-card-list">
      <mini-card
        :CardTitle="'Income'"
        @btn-click="addIncome()"
        @card-click="showIncList()"
      ></mini-card>
      <mini-card
        :CardTitle="'Expenses'"
        @btn-click="addExpense()"
        @card-click="showExpList()"
      ></mini-card>
      <mini-card
        :CardTitle="'Category'"
        :SubTitle="'Expenses/Income'"
        @btn-click="addCategory()"
        @card-click="showCatgList()"
      ></mini-card>
      <mini-card
        :CardTitle="'Ledgers'"
        @btn-click="addCustomer()"
        @card-click="showCustList()"
      ></mini-card>
      <!-- <mini-card
        :CardTitle="'Reports'"
        :ReportBtn="showbtn"
        @card-click="showReports()"
      ></mini-card>
      <mini-card :CardTitle="'A/c Ledger'" :ReportBtn="showbtn"></mini-card>
      <mini-card :CardTitle="'Sales'"></mini-card>
      <mini-card :CardTitle="'Purchase'"></mini-card>
      <mini-card :CardTitle="'Workers & Employes'"></mini-card>
      <mini-card :CardTitle="'Daily Attendance'"></mini-card>
      <mini-card
        :CardTitle="'Salary Register'"
        :ReportBtn="showbtn"
      ></mini-card>
      <mini-card :CardTitle="'Utility'"></mini-card> -->
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import InfoCard from "../components/InfoCard.vue";
  import MiniCard from "../components/MiniCard.vue";
  import axios from "axios";
  export default {
    name: "Home",
    components: { InfoCard, MiniCard },
    data() {
      return {
        payable: "Rs. 0.00",
        recable: "Rs. 0.00",
        expamt: "Rs. 0.00",
        incamt: "Rs. 0.00",
        balamt: "Rs. 0.00",
        userid: "",
        dashloader: false,
      };
    },
    methods: {
      showCustList() {
        this.$router.push("/Customer");
      },
      addCustomer() {
        this.$router.push("/addcustomer/add/new");
      },
      showCatgList() {
        this.$router.push("/catglist");
      },
      addCategory() {
        this.$router.push("/catg/add/new");
      },
      showExpList() {
        this.$router.push("/explist");
      },
      addExpense() {
        this.$router.push("/exp/add/new");
      },
      showIncList() {
        this.$router.push("/inclist");
      },
      addIncome() {
        this.$router.push("/inc/add/new");
      },
      showReports() {
        this.$router.push("/reports");
      },
      dashData() {
        var apiurl = this.$api_url + "dashboard.php";
        axios
          .post(apiurl, {
            optn: "getdashdata",
            uid: this.userid,
          })
          .then((resp) => {
            if (resp.data.Error == false) {
              this.loader = false;
              var tbldata = resp.data.reclist;
              this.recable = tbldata[0].recable.toString();
              this.payable = tbldata[0].payable.toString();
              this.expamt = tbldata[0].expamt.toString();
              this.incamt = tbldata[0].incamt.toString();
              var balamt = parseFloat(this.incamt) - parseFloat(this.expamt);
              this.balamt = balamt.toFixed(2).toString();
              this.dashloader = false;
            } else {
              console.log(resp.data);
              this.dashloader = false;
            }
          });
      },
    },
    computed: {
      showbtn: function() {
        return true;
      },
    },
    beforeUpdate() {
      this.dashData();
    },
    mounted() {
      this.userid = this.$store.state.UserId;
      this.dashloader = true;
      this.dashData();
    },
  };
</script>

<style scoped>
  .mini-card-list {
    position: relative;
    display: block;
    width: 100%;
    height: fit-content;
    padding-bottom: 50px;
  }

  .mini-card-list div {
    display: inline-block;
  }
  .info-card-list {
    position: relative;
    display: block;
    width: 100%;
    height: fit-content;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .info-card-list .small-card {
    display: inline-block;
    width: 140px;
    margin: 5px;
  }
  .info-card-list .big-card {
    display: inline-block;
    width: 320px;
    margin: 5px;
  }
  @media (max-width: 360px) {
    .info-card-list .small-card {
      display: inline-block;
      margin: 5px;
    }
    .info-card-list .big-card {
      display: inline-block;
      margin: 5px;
    }
    .info-card-list .small-card {
      display: inline-block;
      width: 90px;
      margin: 3px;
    }
    .info-card-list .big-card {
      display: inline-block;
      width: 80%;
      left: 0px;
      right: 0px;
      margin: 5px;
    }

    .mini-card-list div {
      font-size: 8px;
      width: 98px;
    }
  }
</style>
