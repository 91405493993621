<!-- @format -->

<template>
  <button type="button" class="round-btn" :class="BtnClass">
    {{ BtnText }}
  </button>
</template>
<script>
  export default {
    props: {
      BtnText: String,
      BtnClass: String,
    },
  };
</script>
<style scoped>
  .round-btn {
    position: relative;
    outline: none;
    display: block;
    width: 100%;
    height: 35px;
    border-radius: 25px;
    cursor: pointer;
    margin-top: 6px;
    margin-bottom: 6px;
    /* background: rgba(var(--primary), 1); */
  }
  .round-btn-sm {
    position: relative;
    margin-right: 6px;
    outline: none;
    width: 100%;
    height: 25px;
    border-radius: 25px;
    cursor: pointer;
    margin-bottom: 5px;
    font-size: 12px;
  }
</style>
