<!-- @format -->

<template>
  <div class="main-nav">
    <div v-if="showGoback" class="goback-div" @click="goBack()">
      <i class="fa fa-arrow-left"></i>
    </div>
    <div class="goback-div" v-else>
      <i class="fa fa-left"></i>
    </div>
    <router-link to="/"><div class="title-nav">MeetArts</div></router-link>

    <div class="nav-user" @click="showLogout()">
      <img src="../img/userimg.jpg" alt="Not Found" />
    </div>
    <transition name="logout">
      <div v-if="LoggingOut" class="user-logout">
        <div class="user-detail">
          <div class="user-img">
            <img src="../img/userimg.jpg" alt="Not Found" />
          </div>
          <div class="user-name">{{ this.$store.state.UserName }}</div>

          <button
            type="button"
            class="round-btn-fit primary logoutbtn"
            @click="Settings()"
          >
            <span class="fa fa-cogs"></span>
            &nbsp;Settings
          </button>
          <button
            type="button"
            class="round-btn-fit primary logoutbtn"
            @click="Profile()"
          >
            <span class="fas fa-user"></span>
            &nbsp; Profile &nbsp;
          </button>
        </div>
        <button
          type="button"
          class="round-btn danger logoutbtn"
          @click="LogOut()"
        >
          Logout
        </button>
        <button
          type="button"
          class="round-btn primary logoutbtn"
          @click="Cancel()"
        >
          Cancel
        </button>
      </div>
    </transition>
    <div v-if="LoggingOut" class="user-logoutbg"></div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        LoggingOut: false,
        showGoback: true,
      };
    },
    methods: {
      showLogout() {
        this.LoggingOut = true;
      },
      LogOut() {
        this.$store.dispatch("Logout");
        this.$router.replace("login");
        this.LoggingOut = false;
      },
      Cancel() {
        this.LoggingOut = false;
      },
      goBack() {
        var ll = Object.keys(this.$route.params).length;
        if (ll === 0) {
          this.$router.push("/");
        } else {
          this.$router.back();
        }
      },
      Settings() {},
      Profile() {
        this.$router.push("/profile");
        this.Cancel();
      },
    },
    watch: {
      $route(to) {
        if (to.path == "/") {
          this.showGoback = false;
        } else {
          this.showGoback = true;
        }
      },
    },
  };
</script>
<style scoped>
  .main-nav {
    position: relative;
    display: block;
    padding: 5px;
    width: calc(100% - 10px);
    background-color: var(--white);
    box-shadow: 0px 0px 4px 2px var(--shadowcolor);
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
  }

  .main-nav a {
    font-weight: bold;
    color: #2c3e50;
  }

  .main-nav a.router-link-exact-active {
    color: #42b983;
  }

  .main-nav div {
    display: inline-block;
  }

  .goback-div i {
    position: absolute;
    margin: 0px;
    padding: 8px;
    font-size: 16px;
    top: 10px;
    left: 5px;
    cursor: pointer;
    z-index: 99;
  }
  .goback-div i:hover {
    color: var(--white);
    border-radius: 50%;
    background-color: var(--dark);
    box-shadow: 0 2px 4px 0px var(--dark);
  }

  .round-btn-fit {
    position: relative;
    outline: none;
    max-width: fit-content;
    min-height: 25px;
    border-radius: 25px;
    cursor: pointer;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 12px;
    padding: 8px;
  }

  .title-nav {
    position: relative;
    display: block;
    height: inherit;
    top: -12px;
    width: calc(100% - 75px);
    color: var(--darktxt);
    font-weight: 600;
  }
  .nav-user {
    position: relative;
    width: 35px;
    height: 35px;
    border: 0px solid var(--dark);
    border-radius: 50%;
    cursor: pointer;
  }
  .nav-user img {
    position: relative;
    width: inherit;
    height: inherit;
  }

  .user-logout {
    position: absolute;
    z-index: 99999;
    top: 45px;
    left: 0px;
    right: 0px;
    display: block;
    margin: 0px auto !important;
    padding: 15px;
    width: calc(100% - 60px);
    background-color: var(--white);
    box-shadow: 0px 0px 4px 1px var(--shadowcolor);
    border-radius: 15px;
    max-width: 300px;
    transition: all 0.8s;
  }
  .user-logoutbg {
    position: fixed;
    display: block;
    padding: 15px;
    top: 0px;
    left: 0px;
    z-index: 9999;
    width: 100%;
    height: 100vh;
    background-color: #0000009c;
  }

  .user-detail {
    position: relative;
    width: 100%;
    margin-bottom: 15px;
  }
  .user-img {
    position: relative;
    display: block;
    top: 0px;
    left: 0px;
    width: 100%;
    margin: 0px;
    padding: 0px;
    height: fit-content;
    text-align: center;
    justify-content: center;
  }
  .user-img img {
    position: relative;
    width: 150px;
    height: 150px;
    border: 1px solid var(--dark);
    border-radius: 50%;
  }
  .user-name {
    position: relative;
    display: block;
    width: 100%;
    text-align: center;
  }
  .logoutbtn {
    width: 100%;
  }

  .logout-enter-from,
  .logout-leave-to {
    opacity: 0;
  }

  .enterdiv {
    animation: fadeIn 0.8s;
  }
  .leavediv {
    animation: fadeOut 0.8s;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
</style>
