<!-- @format -->

<template>
  <div class="controls">
    <round-btn
      v-if="ShowAddBtn"
      :BtnClass="'primary'"
      :BtnText="'Add'"
      @click="addBtn()"
      class="side-btn"
    ></round-btn>
    <select
      class="txt-box"
      :value="modelValue"
      @change="$emit('update:modelValue', $event.target.value)"
    >
      <option :value="data.val" v-for="data in CmbData" :key="data.index">{{
        data.name
      }}</option>
    </select>
    <label>{{ Label }}</label>
  </div>
</template>
<script>
  import RoundBtn from "./RoundBtn.vue";

  export default {
    components: {
      RoundBtn,
    },
    props: ["Label", "modelValue", "CmbData", "ShowAddBtn"],
    emits: ["add-btn", "update"],
    data() {
      return {};
    },
    methods: {
      addBtn() {
        this.$emit("add-btn");
      },
    },
    beforeUpdate() {},
  };
</script>

<style scoped>
  .side-btn {
    position: relative;
    width: 50px;
    height: 22px;
    padding: 0px;
    top: -10px;
    left: calc(100% - 60px);
  }
  .txt-box {
    position: relative;
    display: block;
    width: calc(100% - 10px);
    height: 40px;
    border-radius: 25px;
    padding-left: 15px;
    padding-right: 15px;
    outline: none;
    font-size: 20px;
    margin: 5px;
    margin-bottom: 10px;
    border: 1px solid whitesmoke;
    transition: ease-in-out all 0.3s;
    box-shadow: 0px 1px 2px 0px var(--darkshadowcolor);
  }
  .txt-box:focus {
    box-shadow: 0px 3px 4px 2px var(--darkshadowcolor);
  }
  /* .txt-box:focus::placeholder {
    color: transparent;
  } */

  label {
    position: relative;
    color: var(--dark);
    text-align: left;
    transition: ease-in-out all 0.3s;
  }

  .txt-box:focus + label {
    font-weight: bold;
    margin-left: 8px;
    text-shadow: 1px 1px var(--darkshadowcolor);
  }
  .controls {
    display: flex;
    flex-direction: column-reverse;
  }

  .txtarea-box {
    position: relative;
    display: block;
    width: calc(100% - 32px);
    border-radius: 15px;
    padding-left: 12px;
    padding-top: 8px;
    padding-right: 8px;
    outline: none;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    margin: 5px;
    margin-bottom: 10px;
    border: 1px solid whitesmoke;
    transition: ease-in-out all 0.3s;
    box-shadow: 0px 1px 2px 0px var(--darkshadowcolor);
  }
  .txtarea-box:focus {
    box-shadow: 0px 3px 4px 2px var(--darkshadowcolor);
  }

  .txtarea-box:focus + label {
    font-weight: bold;
    margin-left: 8px;
    text-shadow: 1px 1px var(--darkshadowcolor);
  }
</style>
