<!-- @format -->

<template>
  <div class="loginpg">
    <div id="bubble-div"></div>

    <div class="form-div" v-if="!created">
      <img src="../img/Meetarts.png" alt="No Image" class="logoimg" />
      <br />
      <br />
      <span class="h1-lbl">Sign Up</span>

      <div class="warn" :class="errorclass" v-if="errormsg != ''">
        {{ errormsg }}
      </div>
      <loader-div v-if="loader" :LoaderText="loadertext"></loader-div>
      <input-box
        :Label="'E-Mail*'"
        v-model="uemail"
        :Maxlength="150"
        :InputType="'text'"
        ref="uemail"
        :Hint="'e.g.: abc@gml.com, xyz@yah.co ...'"
      />
      <input-box
        :Label="'User Name*'"
        v-model="uname"
        :Maxlength="20"
        :InputType="'text'"
        :Hint="
          'Must be Atleast 6 Charac. without Spaces e.g. Teddy19,Prince13,Hello19'
        "
      />
      <input-box
        :Label="'Password*'"
        v-model="upass"
        :Maxlength="20"
        :InputType="'password'"
        :Hint="'Must be Atleast 6 Characters & without Spaces.'"
      />
      <input-box
        :Label="'Confirm Password*'"
        v-model="cupass"
        :Maxlength="20"
        :InputType="'password'"
        :Hint="'Please Re-Confirm your password'"
      />

      <round-btn
        :BtnClass="'success'"
        :BtnText="'Sign Up'"
        @click="createUser()"
        id="loginbtn"
      ></round-btn>
      <router-link to="login" class="link">Already Have Account?</router-link>
    </div>
    <div class="form-div" v-else>
      <h1 class="h1-lbl">Congratulations!</h1>
      <div class="warn" :class="errorclass" v-if="errormsg != ''">
        {{ errormsg }}
      </div>
      <router-link to="login" class="link">Click Here to Sign In</router-link>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import LoaderDiv from "../components/LoaderDiv.vue";
  import InputBox from "../components/InputBox.vue";
  import RoundBtn from "../components/RoundBtn.vue";

  export default {
    name: "SignUp",
    components: {
      InputBox,
      RoundBtn,
      LoaderDiv,
      //   DialogBox,
    },

    props: {},
    data() {
      return {
        loader: false,
        uname: "",
        upass: "",
        uemail: "",
        cupass: "",
        isFormValid: true,
        errormsg: "",
        errorclass: "danger",
        created: false,
      };
    },
    methods: {
      async createUser() {
        this.errormsg = "";
        this.isFormValid = true;
        await this.validateForm();
        if (!this.isFormValid) {
          this.errorclass = "danger";
          document.getElementById("uemail").focus();
        } else {
          this.errormsg = "Creating User Please Wait...";
          this.errorclass = "alert";
          var apiurl = this.$api_url + "appusers.php";
          await axios
            .post(apiurl, {
              optn: "create",
              uname: this.uname,
              uemail: this.uemail,
              upass: this.upass,
            })
            .then((result) => {
              if (result.data.status == true) {
                this.errorclass = "success";
                this.created = true;
                this.errormsg = result.data.Message;
              }
            });
        }
      },
      async validateForm() {
        this.uname = this.removespace(this.uname);
        this.upass = this.removespace(this.upass);
        this.uemail = this.removespace(this.uemail);
        this.cupass = this.removespace(this.cupass);
        if (
          this.uname == "" ||
          this.upass == "" ||
          this.uemail == "" ||
          this.cupass == ""
        ) {
          this.isFormValid = false;
          this.errormsg += "Please Enter The Details!\n ";
        } else {
          if (this.uname.length < 6 || this.uname.length > 20) {
            this.isFormValid = false;
            this.errormsg += "User Name not Valid !\n";
          } else {
            await this.checkuserexist();
          }
          if (!this.validateEmail(this.uemail)) {
            this.isFormValid = false;
            this.errormsg += "E-mail id is not Valid !\n";
          } else {
            await this.checkemailexist();
          }

          if (this.upass.length < 6 || this.upass.length > 20) {
            this.isFormValid = false;
            this.errormsg += "Password is not Valid !\n ";
          }

          if (this.cupass != this.upass) {
            this.isFormValid = false;
            this.errormsg += "Password Does Not Match!\n";
          }
        }
      },
      validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
      },
      removespace(xval) {
        var xstr = xval.trim();
        var mystr = xstr.replace(/\s/g, "");
        return mystr;
      },
      async checkuserexist() {
        if (this.uname != "") {
          var apiurl = this.$api_url + "appusers.php";
          await axios
            .post(apiurl, {
              optn: "finduser",
              uname: this.uname,
            })
            .then((response) => {
              if (response.data.Error == true) {
                this.errormsg = response.data.ErrorMsg;
                this.isFormValid = false;
              } else {
                if (response.data.Found) {
                  this.isFormValid = false;
                  this.errorclass = "danger";
                  this.errormsg += "User Name Already Taken!\n";
                }
              }
            })
            .catch((errlog) => {
              this.errormsg = "Error In Connecting Server!" + errlog;
              this.errorclass = "danger";
            });
        }
      },
      async checkemailexist() {
        if (this.uemail != "") {
          var apiurl = this.$api_url + "appusers.php";
          await axios
            .post(apiurl, {
              optn: "findemail",
              uemail: this.uemail,
            })
            .then((response) => {
              if (response.data.Error == true) {
                this.errormsg = response.data.ErrorMsg;
                this.errorclass = "danger";
                this.isFormValid = false;
              } else {
                if (response.data.Found) {
                  this.errormsg += "E-Mail Id Already Exists!\n";
                  this.errorclass = "danger";
                  this.isFormValid = false;
                }
              }
            })
            .catch((errlog) => {
              console.log(errlog);
            });
        }
      },
      createBubble(bubbleNo) {
        var winWidth = screen.availWidth;
        var winHeight = screen.availHeight;
        var PosX = 0;
        var PosY = 0;
        var bubblediv = document.getElementById("bubble-div");
        var clrclass = [
          "circle-red",
          "circle-red",
          "circle-red",
          "circle-blue",
          "circle-blue",
          "circle-yellow",
          "circle-yellow",
          "circle-yellow",
          "circle-green",
          "circle-green",
          "circle-green",
        ];
        var dimclass = [
          { width: "50px", height: "50px" },
          { width: "80px", height: "80px" },
          { width: "100px", height: "100px" },
          { width: "120px", height: "120px" },
          { width: "120px", height: "120px" },
          { width: "120px", height: "120px" },
          { width: "150px", height: "150px" },
          { width: "180px", height: "180px" },
          { width: "220px", height: "220px" },
          { width: "100px", height: "100px" },
          { width: "120px", height: "120px" },
          { width: "150px", height: "150px" },
          { width: "50px", height: "50px" },
          { width: "80px", height: "80px" },
          { width: "100px", height: "100px" },
        ];
        var newbubble = document.createElement("div");
        newbubble.id = "bubble" + bubbleNo;
        newbubble.classList.add("bubble");
        var BubbelSetting = {
          getRandomInt(max, min) {
            return Math.round(Math.random() * (max - min) + min, 0);
          },
          colour: function() {
            var clrnumber = this.getRandomInt(0, 11);
            var clr = clrclass[clrnumber];
            return clr;
          },
          size: function() {
            var bubdim = dimclass[this.getRandomInt(0, 14)];
            return bubdim;
          },
        };
        newbubble.classList.add(BubbelSetting.colour());
        var b = BubbelSetting.size();
        newbubble.style.width = b.width;
        newbubble.style.height = b.height;
        PosX = this.getRandomInt((winWidth / 50) * -1, winWidth);
        PosY = this.getRandomInt((winWidth / 50) * -1, winHeight);
        newbubble.style.left = PosX + "px";
        newbubble.style.top = PosY + "px";
        setInterval(() => {
          if (PosX > winWidth || PosX < 0) {
            newbubble.className = "";
            newbubble.classList.add("bubble");
            newbubble.classList.add(BubbelSetting.colour());
            b = BubbelSetting.size();
            newbubble.style.width = b.width;
            newbubble.style.height = b.height;
            PosX = this.getRandomInt((winWidth / 50) * -1, winWidth);
          }
          if (PosY > winHeight || PosY < 0) {
            newbubble.className = "";
            newbubble.classList.add("bubble");
            newbubble.classList.add(BubbelSetting.colour());
            b = BubbelSetting.size();
            newbubble.style.width = b.width;
            newbubble.style.height = b.height;
            PosY = this.getRandomInt((winWidth / 50) * -1, winHeight);
          }
          PosX += this.getRandomInt(-2, 2);
          PosY += this.getRandomInt(-2, 5);

          // PosX += this.getRandomInt(bubbleNo * -1, winWidth);
          // PosY += this.getRandomInt(bubbleNo * -1, winHeight);
          newbubble.style.left = PosX + "px";
          newbubble.style.top = PosY + "px";
        }, 200);

        newbubble.addEventListener("click", function() {
          var bub = document.getElementById(this.id);
          bub.classList.add("brust-bubble");
          setTimeout(() => {
            bub.classList.remove("brust-bubble");
            b = BubbelSetting.size();
            PosX = 0;
            PosY = 0;
            bub.style.width = b.width;
            bub.style.height = b.height;
            bub.style.top = BubbelSetting.getRandomInt(150, winHeight) + "px";
            bub.style.left = BubbelSetting.getRandomInt(20, winWidth) + "px";
          }, 800);
        });
        bubblediv.appendChild(newbubble);
      },
      getRandomInt(max, min) {
        return Math.round(Math.random() * (max - min) + min, 0);
      },
      genBubble() {
        var winWidth = screen.availWidth;
        var bcnt = 10;
        if (winWidth > 700) {
          bcnt = 15;
        }
        if (winWidth > 1024) {
          bcnt = 20;
        }
        if (winWidth > 1300) {
          bcnt = 30;
        }
        for (var i = 1; i <= bcnt; i++) {
          this.createBubble(i);
        }
      },
    },
    mounted() {
      this.genBubble();
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .form-div {
    position: relative;
    display: block;
    padding: 15px;
    margin: 0px auto !important;
    width: calc(95% - 30px);
    border-radius: 15px;
    max-width: 300px;
    top: 50px;
    max-width: 300px;
    border: 1px solid white;
    box-shadow: 0px 0px 12px 1px lightgray;
    background-color: #ffffff7a;
    backdrop-filter: blur(5px);
  }
  .sign-in {
    position: relative;
    top: 120px;
  }

  .h1-lbl {
    position: relative;
    margin: 0px;
    padding: 0px;
    font-size: 26px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  .warn {
    padding: 4px;
    border-radius: 15px;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  #loginbtn {
    position: relative;
    margin-bottom: 25px;
    font-size: 16px;
    padding: 8px;
  }
  .logoimg {
    position: absolute;
    display: block;
    margin: 0px auto !important;
    top: -45px;
    left: 0px;
    right: 0px;
    width: 90px;
    height: 90px;
  }

  .round-circle {
    position: fixed;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    top: 60px;
    left: -45px;
  }

  .round-circle-lg {
    position: fixed;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    top: 400px;
    left: 165px;
  }

  .round-circle-sm {
    position: fixed;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    top: 390px;
  }

  .round-circle-md {
    position: fixed;
    top: 190px;
    left: 250px;
    width: 180px;
    height: 180px;
    border-radius: 50%;
  }

  .circle-red {
    border: 1px solid var(--danger);
    background-color: var(--danger);
    color: var(--lighttxt);
    box-shadow: 0px 2px 20px 8px var(--dangerlight);
  }
  .circle-blue {
    border: 1px solid var(--primary);
    background-color: var(--primary);
    color: var(--lighttxt);
    box-shadow: 0px 2px 20px 8px var(--primarylight);
  }
  .circle-yellow {
    border: 1px solid var(--warning);
    background-color: var(--warning);
    color: var(--lighttxt);
    box-shadow: 0px 2px 20px 8px var(--warninglight);
  }

  .circle-green {
    border: 1px solid var(--success);
    background-color: var(--success);
    color: var(--lighttxt);
    box-shadow: 0px 2px 20px 8px var(--successlight);
  }
  .link {
    position: relative;
    display: block;
    padding: 8px;
    border-radius: 20px;
    margin-bottom: 15px;
    text-decoration: none;
    color: white;
    border: 1px solid var(--primary);
    background-color: var(--primary);
    color: var(--lighttxt);
    box-shadow: 0px 2px 20px 8px var(--primarylight);
  }
</style>
