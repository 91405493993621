<!-- @format -->

<template>
  <div class="card">
    <div class="card-title" @click="CardClick()">
      {{ CardTitle }}
      <span class="sub-title">{{ SubTitle }}</span>
    </div>
    <div class="card-btn">
      <button
        v-if="!ReportBtn"
        type="button"
        class="round-btn primary"
        @click="BtnClick()"
      >
        <i class="fa fa-plus"></i>
      </button>

      <button v-if="ReportBtn" type="button" class="round-btn warning">
        <i class="fas fa-chart-bar"></i>
      </button>
    </div>
  </div>
</template>

<script>
  export default {
    emits: ["card-click", "btn-click"],
    props: {
      CardTitle: String,
      SubTitle: String,
      ReportBtn: Boolean,
    },
    methods: {
      CardClick() {
        this.$emit("card-click");
      },
      BtnClick() {
        this.$emit("btn-click");
      },
    },
  };
</script>

<style scoped>
  .card {
    position: relative;
    display: block;
    margin: 10px;
    padding: 8px;
    width: calc(160px - 36px);
    min-height: 130px;
    background-color: var(--white);
    border-radius: 8px;
    box-shadow: 0px 5px 8px 2px var(--darkshadowcolor);
  }

  .card:hover {
    box-shadow: 0px 2px 4px 2px var(--darkshadowcolor);
  }
  .card:focus {
    box-shadow: 0px 2px 4px 2px var(--darkshadowcolor);
  }
  .card-title {
    position: relative;
    display: block;
    width: 100%;
    top: 5px;
    font-size: 24px;
    min-height: 80px;
    margin-bottom: 10px;
    font-weight: 500;
    color: var(--darktxt);
    text-align: left;
    cursor: pointer;
  }
  .sub-title {
    position: relative;
    display: block;
    width: 100%;
    font-size: 12px;
    font-style: italic;
    color: gray;
    text-align: left;
  }
  .card-btn {
    position: relative;
    display: block;
    width: 100%;
    text-align: right;
  }
  .round-btn {
    position: relative;
    margin-right: 6px;
    outline: none;
    width: 35px;
    height: 35px;
    border-radius: 25px;
    cursor: pointer;
    /* background: rgba(var(--primary), 1); */
  }

  @media (max-width: 360px) {
    .card {
      width: calc(160px - 36px);
      min-height: 80px;
    }
    .card-title {
      font-size: 18px;
      min-height: 60px;
    }
    .sub-title {
      font-size: 8px;
    }
  }
  /* Colors */
  .primary {
    border: 1px solid var(--primary);
    background-color: var(--primary);
    color: var(--lighttxt);
    box-shadow: 0 2px 4px 0px var(--primarylight);
  }
  .primary:hover {
    box-shadow: 0 0px 8px 0px var(--primary);
  }
  .primary:focus {
    box-shadow: 0 0px 8px 0px var(--primary);
  }

  .warning {
    border: 1px solid var(--warning);
    background-color: var(--warning);
    color: var(--lighttxt);
    box-shadow: 0 2px 4px 0px var(--warninglight);
  }
  .warning:hover {
    box-shadow: 0 0px 8px 0px var(--warning);
  }
  .warning:focus {
    box-shadow: 0 0px 8px 0px var(--warning);
  }
</style>
