/** @format */
var CryptoJS = require("crypto-js");

const MyFun = {
  hidetxt(str) {
    var arr1 = [];
    for (var n = 0, l = str.length; n < l; n++) {
      var hex = Number(str.charCodeAt(n)).toString(16);
      arr1.push(hex);
    }
    return arr1.join("");
  },
  showtxt(str1) {
    var str = "";
    if (str1 != undefined) {
      var hex = str1;
      for (var n = 0; n < hex.length; n += 2) {
        str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
      }
    }
    return str;
  },
  copytxt(xid) {
    var copyText = document.getElementById(xid).innerHTML;
    // copyText.select();
    // copyText.setSelectionRange(0, 99999);

    navigator.clipboard.writeText(copyText);
  },
  encrypt(str) {
    var txt = CryptoJS.AES.encrypt(str, "MeetArts").toString();
    txt = this.hidetxt(txt);
    return txt;
  },
  decrypt(str) {
    str = this.showtxt(str);
    var txt = CryptoJS.AES.decrypt(str, "MeetArts").toString(CryptoJS.enc.Utf8);
    return txt;
  },
  tobritish(xdt) {
    var ndt = "";
    if (xdt != "" && xdt != undefined) {
      var yy = xdt.substr(0, 4);
      var mm = xdt.substr(5, 2);
      var dd = xdt.substr(8, 2);
      ndt = dd + "/" + mm + "/" + yy;
    }
    return ndt;
  },
};
export default MyFun;
