<!-- @format -->

<template>
  <div class="card" :class="'card-' + CardColor">
    <div class="card-title">{{ CardTitle }}</div>
    <div class="card-detail">
      <span class="fa fa-sync fa-spin detloader" v-if="Loader"></span>
      &#8377; {{ CardDetail }}
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      CardTitle: String,
      CardDetail: String,
      CardColor: String,
      Size: String,
      Loader: Boolean,
    },
  };
</script>
<style></style>
<style scoped>
  .detloader {
    position: relative;
    font-size: 12px;
    right: 5px;
  }
  .card {
    position: relative;
    display: block;
    margin: 12px;
    padding: 15px;
    width: calc(100% - 54px);
    border-radius: 8px;
    cursor: pointer;
  }

  .card-title {
    position: relative;
    display: block;
    font-size: 20px;
    font-weight: 500;
    color: var(--lighttxt);
    text-align: left;
  }
  .card-detail {
    position: relative;
    display: block;
    font-size: 18px;
    font-weight: 500;
    text-align: right;
    color: var(--lighttxt);
    padding: 5px;
  }

  @media (max-width: 360px) {
    .card-title {
      font-size: 15px;
      font-weight: 500;
    }
    .card-detail {
      font-size: 14px;
      font-weight: 500;
      text-align: right;
      padding: 5px;
    }
  }

  /* Card Colours*/
  .card-warning {
    background-color: var(--warning);
    box-shadow: 0px 5px 8px 2px var(--darkshadowcolor);
  }

  .card-warning:focus {
    background-color: var(--warningdark);
    box-shadow: 0px 0px 4px 2px var(--darkshadowcolor);
  }

  .card-warning:hover {
    background-color: var(--warningdark);
    box-shadow: 0px 0px 4px 2px var(--darkshadowcolor);
  }

  .card-primary {
    background-color: var(--primary);
    box-shadow: 0px 5px 8px 2px var(--darkshadowcolor);
  }

  .card-primary:focus {
    background-color: var(--primarydark);
    box-shadow: 0px 0px 4px 2px var(--darkshadowcolor);
  }

  .card-primary:hover {
    background-color: var(--primarydark);
    box-shadow: 0px 0px 4px 2px var(--darkshadowcolor);
  }

  .card-success {
    background-color: var(--success);
    box-shadow: 0px 5px 8px 2px var(--darkshadowcolor);
  }

  .card-success:focus {
    background-color: var(--successdark);
    box-shadow: 0px 0px 4px 2px var(--darkshadowcolor);
  }

  .card-success:hover {
    background-color: var(--successdark);
    box-shadow: 0px 0px 4px 2px var(--darkshadowcolor);
  }

  .card-danger {
    background-color: var(--danger);
    box-shadow: 0px 5px 8px 2px var(--darkshadowcolor);
  }

  .card-danger:focus {
    background-color: var(--dangerdark);
    box-shadow: 0px 0px 4px 2px var(--darkshadowcolor);
  }

  .card-danger:hover {
    background-color: var(--dangerdark);
    box-shadow: 0px 0px 4px 2px var(--darkshadowcolor);
  }

  .card-default {
    background-color: var(--default);
    box-shadow: 0px 5px 8px 2px var(--darkshadowcolor);
  }

  .card-default:focus {
    background-color: var(--defaultdark);
    box-shadow: 0px 0px 4px 2px var(--darkshadowcolor);
  }

  .card-default:hover {
    background-color: var(--defaultdark);
    box-shadow: 0px 0px 4px 2px var(--darkshadowcolor);
  }

  .card-default .card-detail,
  .card-default .card-title {
    color: black;
  }
</style>
