<!-- @format -->

<template>
  <div class="form-div">
    <loader-div v-if="loader" :LoaderText="loadertext"></loader-div>
    <dialog-box
      v-if="dialog"
      @cancel="toggleDialog('', '', '')"
      :dialogtype="dialogtype"
      @confirm="delRec()"
    >
      <template v-slot:title>
        {{ dialogtitle }}
      </template>
      <template v-slot:body>
        {{ dialogbody }}
      </template>
    </dialog-box>
    <div class="form-title">
      <div class="title-txt">Ledgers ({{ EntryType }})</div>
      <i
        v-if="Optn == 'edit'"
        class="fa fa-trash danger-icon"
        data-toggle="tooltip"
        title="Delete"
        @click="toggleDialog('confirm', 'Delete', 'Do you want to Delete?')"
      ></i>
    </div>
    <div class="form-detail">
      <image-box
        @ImageSelect="getImgData"
        @RemoveImage="delImg"
        ref="myImg"
        :ImageData="showImg"
      ></image-box>
      <input-box
        :Label="'Name*'"
        v-model="gln"
        :Maxlength="80"
        :InputType="'text'"
        ref="custname"
      />

      <cmb-box :Label="'Type *'" :CmbData="cmbdata" v-model="ctype"></cmb-box>

      <input-box
        :Label="'Contact No.'"
        :Maxlength="30"
        v-model="mobno"
        :InputType="'text'"
      />
      <input-box
        :Label="'Email'"
        :Maxlength="150"
        v-model="email"
        :InputType="'text'"
      />
      <input-box
        :Label="'Opening Balance'"
        :TipText="'0.00'"
        v-model="opnbal"
        :InputType="'number'"
      />
      <input-box
        :Label="'Address'"
        :TipText="'Address'"
        v-model="address"
        :InputType="'textarea'"
        :Maxlength="150"
      />
      <round-btn
        :BtnClass="'success'"
        :BtnText="'Save'"
        @click="saveForm()"
      ></round-btn>
      <round-btn
        v-if="subform == true"
        :BtnClass="'danger'"
        :BtnText="'Cancel'"
        @click="cancel()"
      ></round-btn>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import LoaderDiv from "../components/LoaderDiv.vue";
  import InputBox from "../components/InputBox.vue";
  import RoundBtn from "../components/RoundBtn.vue";
  import DialogBox from "../components/DialogBox.vue";
  import CmbBox from "../components/CmbBox.vue";
  import ImageBox from "../components/ImageBox.vue";
  import MyFun from "../js/MyFun";

  export default {
    components: {
      InputBox,
      RoundBtn,
      LoaderDiv,
      DialogBox,
      CmbBox,
      ImageBox,
    },
    emits: ["cancel-btn"],
    props: ["mglc", "moptn", "subform"],
    data() {
      return {
        Optn: this.moptn,
        gln: "",
        glc: "",
        mobno: "",
        email: "",
        address: "",
        ctype: "B",
        custimg: "",
        opnbal: null,
        EntryType: "",
        loader: false,
        loadertext: "",
        dialog: false,
        userid: this.$store.state.UserId,
        dialogtype: "",
        dialogtitle: "",
        dialogbody: "",
        showImg: "",
        imgdata: "",
        cmbdata: [
          {
            name: "Buyer",
            val: "B",
          },
          {
            name: "Seller",
            val: "S",
          },
        ],
      };
    },
    methods: {
      async saveForm() {
        if (this.isFormVaild()) {
          await this.UploadImg();
          this.loader = true;
          this.loadertext = "Saving";
          var apiurl = this.$api_url + "custmst.php";
          axios
            .post(apiurl, {
              optn: this.moptn,
              uid: this.userid,
              gln: this.gln,
              glc: this.glc,
              mobno: this.mobno,
              email: this.email,
              address: this.address,
              opnbal: this.opnbal,
              ctype: this.ctype,
              custimg: this.custimg,
            })
            .then((resp) => {
              this.loader = false;
              if (resp.data.Error == false) {
                this.toggleDialog("success", "Saved", "Record is Saved!");
                if (this.moptn == "add") {
                  this.fablank();
                } else {
                  this.$router.replace("/customer");
                }
              } else {
                this.toggleDialog("error", "Error", resp.data);
              }
            });
        } else {
          this.toggleDialog(
            "warning",
            "No Info. to Save!",
            "Please fill * marked boxes before saving!"
          );
        }
      },
      fablank() {
        this.Optn = this.moptn;
        this.gln = "";
        this.mobno = "";
        this.email = "";
        this.address = "";
        this.imgdata = "";
        this.opnbal = null;
        this.EntryType = "Add";
        this.clearImg();
        this.focusCust();
      },
      getRec() {
        this.loader = true;
        this.loadertext = "Loading";
        var apiurl = this.$api_url + "custmst.php";
        axios
          .post(apiurl, {
            optn: "getrec",
            uid: this.userid,
            glc: this.glc,
          })
          .then((resp) => {
            this.loader = false;
            if (resp.data.cust.length > 0) {
              var custdet = resp.data.cust[0];
              this.glc = custdet.glc;
              this.gln = custdet.gln;
              this.address = custdet.address;
              this.opnbal = custdet.opnbal;
              this.mobno = custdet.mobno;
              this.email = custdet.email;
              this.custimg = custdet.custimg;
              this.showImg = custdet.custimg;
            } else {
              this.toggleDialog("error", "Error", "Record Not Found!");
              this.$router.replace("/Customer");
            }
          });
      },
      toggleDialog(dlgtype, dlgtitle, dlgbody) {
        this.dialogtype = dlgtype;
        this.dialogtitle = dlgtitle;
        this.dialogbody = dlgbody;
        this.dialog = !this.dialog;
      },
      delRec() {
        this.loader = true;
        this.loadertext = "Deleting";
        var apiurl = this.$api_url + "custmst.php";
        axios
          .post(apiurl, {
            optn: "delete",
            uid: this.userid,
            glc: this.glc,
          })
          .then((resp) => {
            this.toggleDialog("", "", "");
            if (resp.data.Error == false) {
              this.loader = false;
              this.$router.replace("/Customer");
            } else {
              this.toggleDialog("error", "Error", resp.data);
            }
          });
      },
      focusCust() {
        this.$refs.custname.$el.children[0].focus();
      },
      isFormVaild() {
        var formvalid = true;
        if (this.gln == "") {
          formvalid = false;
        }
        if (this.opnbal == "" || this.opnbal == null) {
          this.opnbal = 0;
        }
        return formvalid;
      },
      getImgData(e) {
        this.imgdata = e;
        if (e.name != "" && e.name != null) {
          this.custimg = "upload/" + this.userid + "_" + e.name;
        } else {
          this.custimg = "";
        }
      },
      async UploadImg() {
        if (this.imgdata != "" && this.imgdata != null) {
          this.loader = true;
          this.loadertext = "Uploading..";
          let formData = new FormData();
          formData.append("file", this.imgdata);
          formData.append("imgName", this.custimg);
          var apiurl = this.$api_url + "upload.php";
          await axios
            .post(apiurl, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(function(data) {
              if (data.data !== "done") {
                // alert("Failed to Upload!!" + data);
                console.log(data);
              }
            })
            .catch(function(fail) {
              console.log(fail);
            });
          this.loader = true;
        }
      },
      clearImg() {
        var child = this.$refs.myImg;
        child.removeImg();
        this.showImg = "";
        this.custimg = "";
        this.imgdata = "";
      },
      delImg() {
        this.showImg = "";
        this.custimg = "";
        this.imgdata = "";
      },
      cancel() {
        this.$emit("cancel-btn");
      },
    },
    created() {
      if (this.moptn == "edit") {
        this.EntryType = "Modify";
        this.glc = MyFun.decrypt(this.mglc);
        this.getRec();
      } else {
        this.EntryType = "Add";
      }
    },
    mounted() {
      this.focusCust();
    },
  };
</script>

<style scoped>
  .form-div {
    position: relative;
    display: block;
    margin: 0px;
    padding: 0px;
  }
  .form-title {
    position: relative;
    display: block;
    padding: 5px;
    width: calc(100% - 10px);
    background-color: var(--dark);
    color: var(--white);
  }
  .title-txt {
    position: relative;
    width: calc(100% - 25px);
    display: inline-block;
  }
  .form-detail {
    display: block;
    padding: 5px;
    width: calc(100% - 10px);
    background-color: var(--white);
    background-color: whitesmoke;
  }

  .danger-icon {
    position: relative;
    font-size: 20px;
    right: 15px;
    color: var(--danger);
    cursor: pointer;
  }
  .danger-icon:hover {
    color: var(--white);
    text-shadow: 2px 0px 6px var(--danger);
  }
</style>
