<!-- @format -->

<template>
  <div class="loaderdiv">
    <div class="cssload-dots">
      <div class="cssload-dot"></div>
      <div class="cssload-dot"></div>
      <div class="cssload-dot"></div>
      <div class="cssload-dot"></div>
      <div class="cssload-dot"></div>
    </div>

    <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <filter id="goo">
          <feGaussianBlur
            in="SourceGraphic"
            result="blur"
            stdDeviation="12"
          ></feGaussianBlur>
          <feColorMatrix
            in="blur"
            mode="matrix"
            values="1 0 0 0 0	0 1 0 0 0	0 0 1 0 0	0 0 0 18 -7"
            result="goo"
          ></feColorMatrix>
          <!--<feBlend in2="goo" in="SourceGraphic" result="mix" ></feBlend>-->
        </filter>
      </defs>
    </svg>
    <br />
    <span class="loadingtxt">{{ LoaderText }}....</span>
  </div>
  <div class="bgdark"></div>
</template>
<script>
  export default {
    props: ["LoaderText"],
  };
</script>
<style scoped>
  .bgdark {
    position: fixed;
    top: 0px;
    width: 100%;
    height: 100vh;
    background-color: #00000049;
    z-index: 99;
  }

  .loaderdiv {
    position: absolute;
    top: 15%;
    margin: 0px auto !important;
    left: 0;
    right: 0;
    width: 250px;
    height: 250px;
    border-radius: 25px;
    z-index: 999;
  }
  .loadingtxt {
    position: relative;
    display: block;
    font-size: 24px;
    top: 25px;
    color: white;
  }
  .cssload-dots {
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    outline: 1px solid red;
    filter: url(#goo);
    -o-filter: url(#goo);
    -ms-filter: url(#goo);
    -webkit-filter: url(#goo);
    -moz-filter: url(#goo);
  }

  .cssload-dot {
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
  }
  .cssload-dot:before {
    content: "";
    width: 31px;
    height: 31px;
    border-radius: 45px;
    background: rgb(251, 211, 1);
    position: absolute;
    left: 50%;
    transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    margin-left: -15.5px;
    margin-top: -15.5px;
  }

  .cssload-dot:nth-child(5):before {
    z-index: 100;
    width: 40.5px;
    height: 40.5px;
    margin-left: -20.75px;
    margin-top: -20.75px;
    animation: cssload-dot-colors 3.8s ease infinite;
    -o-animation: cssload-dot-colors 3.8s ease infinite;
    -ms-animation: cssload-dot-colors 3.8s ease infinite;
    -webkit-animation: cssload-dot-colors 3.8s ease infinite;
    -moz-animation: cssload-dot-colors 3.8s ease infinite;
  }

  .cssload-dot:nth-child(1) {
    animation: cssload-dot-rotate-1 3.8s 0s linear infinite;
    -o-animation: cssload-dot-rotate-1 3.8s 0s linear infinite;
    -ms-animation: cssload-dot-rotate-1 3.8s 0s linear infinite;
    -webkit-animation: cssload-dot-rotate-1 3.8s 0s linear infinite;
    -moz-animation: cssload-dot-rotate-1 3.8s 0s linear infinite;
  }
  .cssload-dot:nth-child(1):before {
    background-color: rgb(255, 50, 112);
    animation: cssload-dot-move 3.8s 0s ease infinite;
    -o-animation: cssload-dot-move 3.8s 0s ease infinite;
    -ms-animation: cssload-dot-move 3.8s 0s ease infinite;
    -webkit-animation: cssload-dot-move 3.8s 0s ease infinite;
    -moz-animation: cssload-dot-move 3.8s 0s ease infinite;
  }

  .cssload-dot:nth-child(2) {
    animation: cssload-dot-rotate-2 3.8s 0.95s linear infinite;
    -o-animation: cssload-dot-rotate-2 3.8s 0.95s linear infinite;
    -ms-animation: cssload-dot-rotate-2 3.8s 0.95s linear infinite;
    -webkit-animation: cssload-dot-rotate-2 3.8s 0.95s linear infinite;
    -moz-animation: cssload-dot-rotate-2 3.8s 0.95s linear infinite;
  }
  .cssload-dot:nth-child(2):before {
    background-color: rgb(32, 139, 241);
    animation: cssload-dot-move 3.8s 0.95s ease infinite;
    -o-animation: cssload-dot-move 3.8s 0.95s ease infinite;
    -ms-animation: cssload-dot-move 3.8s 0.95s ease infinite;
    -webkit-animation: cssload-dot-move 3.8s 0.95s ease infinite;
    -moz-animation: cssload-dot-move 3.8s 0.95s ease infinite;
  }

  .cssload-dot:nth-child(3) {
    animation: cssload-dot-rotate-3 3.8s 1.9s linear infinite;
    -o-animation: cssload-dot-rotate-3 3.8s 1.9s linear infinite;
    -ms-animation: cssload-dot-rotate-3 3.8s 1.9s linear infinite;
    -webkit-animation: cssload-dot-rotate-3 3.8s 1.9s linear infinite;
    -moz-animation: cssload-dot-rotate-3 3.8s 1.9s linear infinite;
  }
  .cssload-dot:nth-child(3):before {
    background-color: rgb(175, 225, 2);
    animation: cssload-dot-move 3.8s 1.9s ease infinite;
    -o-animation: cssload-dot-move 3.8s 1.9s ease infinite;
    -ms-animation: cssload-dot-move 3.8s 1.9s ease infinite;
    -webkit-animation: cssload-dot-move 3.8s 1.9s ease infinite;
    -moz-animation: cssload-dot-move 3.8s 1.9s ease infinite;
  }

  .cssload-dot:nth-child(4) {
    animation: cssload-dot-rotate-4 3.8s 2.85s linear infinite;
    -o-animation: cssload-dot-rotate-4 3.8s 2.85s linear infinite;
    -ms-animation: cssload-dot-rotate-4 3.8s 2.85s linear infinite;
    -webkit-animation: cssload-dot-rotate-4 3.8s 2.85s linear infinite;
    -moz-animation: cssload-dot-rotate-4 3.8s 2.85s linear infinite;
  }
  .cssload-dot:nth-child(4):before {
    background-color: rgb(251, 211, 1);
    animation: cssload-dot-move 3.8s 2.85s ease infinite;
    -o-animation: cssload-dot-move 3.8s 2.85s ease infinite;
    -ms-animation: cssload-dot-move 3.8s 2.85s ease infinite;
    -webkit-animation: cssload-dot-move 3.8s 2.85s ease infinite;
    -moz-animation: cssload-dot-move 3.8s 2.85s ease infinite;
  }

  @keyframes cssload-dot-move {
    0% {
      transform: translateY(0);
    }
    18%,
    22% {
      transform: translateY(-63px);
    }
    40%,
    100% {
      transform: translateY(0);
    }
  }

  @-o-keyframes cssload-dot-move {
    0% {
      -o-transform: translateY(0);
    }
    18%,
    22% {
      -o-transform: translateY(-63px);
    }
    40%,
    100% {
      -o-transform: translateY(0);
    }
  }

  @-ms-keyframes cssload-dot-move {
    0% {
      -ms-transform: translateY(0);
    }
    18%,
    22% {
      -ms-transform: translateY(-63px);
    }
    40%,
    100% {
      -ms-transform: translateY(0);
    }
  }

  @-webkit-keyframes cssload-dot-move {
    0% {
      -webkit-transform: translateY(0);
    }
    18%,
    22% {
      -webkit-transform: translateY(-63px);
    }
    40%,
    100% {
      -webkit-transform: translateY(0);
    }
  }

  @-moz-keyframes cssload-dot-move {
    0% {
      -moz-transform: translateY(0);
    }
    18%,
    22% {
      -moz-transform: translateY(-63px);
    }
    40%,
    100% {
      -moz-transform: translateY(0);
    }
  }

  @keyframes cssload-dot-colors {
    0% {
      background-color: rgb(251, 211, 1);
    }
    25% {
      background-color: rgb(255, 50, 112);
    }
    50% {
      background-color: rgb(32, 139, 241);
    }
    75% {
      background-color: rgb(175, 225, 2);
    }
    100% {
      background-color: rgb(251, 211, 1);
    }
  }

  @-o-keyframes cssload-dot-colors {
    0% {
      background-color: rgb(251, 211, 1);
    }
    25% {
      background-color: rgb(255, 50, 112);
    }
    50% {
      background-color: rgb(32, 139, 241);
    }
    75% {
      background-color: rgb(175, 225, 2);
    }
    100% {
      background-color: rgb(251, 211, 1);
    }
  }

  @-ms-keyframes cssload-dot-colors {
    0% {
      background-color: rgb(251, 211, 1);
    }
    25% {
      background-color: rgb(255, 50, 112);
    }
    50% {
      background-color: rgb(32, 139, 241);
    }
    75% {
      background-color: rgb(175, 225, 2);
    }
    100% {
      background-color: rgb(251, 211, 1);
    }
  }

  @-webkit-keyframes cssload-dot-colors {
    0% {
      background-color: rgb(251, 211, 1);
    }
    25% {
      background-color: rgb(255, 50, 112);
    }
    50% {
      background-color: rgb(32, 139, 241);
    }
    75% {
      background-color: rgb(175, 225, 2);
    }
    100% {
      background-color: rgb(251, 211, 1);
    }
  }

  @-moz-keyframes cssload-dot-colors {
    0% {
      background-color: rgb(251, 211, 1);
    }
    25% {
      background-color: rgb(255, 50, 112);
    }
    50% {
      background-color: rgb(32, 139, 241);
    }
    75% {
      background-color: rgb(175, 225, 2);
    }
    100% {
      background-color: rgb(251, 211, 1);
    }
  }

  @keyframes cssload-dot-rotate-1 {
    0% {
      transform: rotate(-105deg);
    }
    100% {
      transform: rotate(270deg);
    }
  }

  @-o-keyframes cssload-dot-rotate-1 {
    0% {
      -o-transform: rotate(-105deg);
    }
    100% {
      -o-transform: rotate(270deg);
    }
  }

  @-ms-keyframes cssload-dot-rotate-1 {
    0% {
      -ms-transform: rotate(-105deg);
    }
    100% {
      -ms-transform: rotate(270deg);
    }
  }

  @-webkit-keyframes cssload-dot-rotate-1 {
    0% {
      -webkit-transform: rotate(-105deg);
    }
    100% {
      -webkit-transform: rotate(270deg);
    }
  }

  @-moz-keyframes cssload-dot-rotate-1 {
    0% {
      -moz-transform: rotate(-105deg);
    }
    100% {
      -moz-transform: rotate(270deg);
    }
  }

  @keyframes cssload-dot-rotate-2 {
    0% {
      transform: rotate(165deg);
    }
    100% {
      transform: rotate(540deg);
    }
  }

  @-o-keyframes cssload-dot-rotate-2 {
    0% {
      -o-transform: rotate(165deg);
    }
    100% {
      -o-transform: rotate(540deg);
    }
  }

  @-ms-keyframes cssload-dot-rotate-2 {
    0% {
      -ms-transform: rotate(165deg);
    }
    100% {
      -ms-transform: rotate(540deg);
    }
  }

  @-webkit-keyframes cssload-dot-rotate-2 {
    0% {
      -webkit-transform: rotate(165deg);
    }
    100% {
      -webkit-transform: rotate(540deg);
    }
  }

  @-moz-keyframes cssload-dot-rotate-2 {
    0% {
      -moz-transform: rotate(165deg);
    }
    100% {
      -moz-transform: rotate(540deg);
    }
  }

  @keyframes cssload-dot-rotate-3 {
    0% {
      transform: rotate(435deg);
    }
    100% {
      transform: rotate(810deg);
    }
  }

  @-o-keyframes cssload-dot-rotate-3 {
    0% {
      -o-transform: rotate(435deg);
    }
    100% {
      -o-transform: rotate(810deg);
    }
  }

  @-ms-keyframes cssload-dot-rotate-3 {
    0% {
      -ms-transform: rotate(435deg);
    }
    100% {
      -ms-transform: rotate(810deg);
    }
  }

  @-webkit-keyframes cssload-dot-rotate-3 {
    0% {
      -webkit-transform: rotate(435deg);
    }
    100% {
      -webkit-transform: rotate(810deg);
    }
  }

  @-moz-keyframes cssload-dot-rotate-3 {
    0% {
      -moz-transform: rotate(435deg);
    }
    100% {
      -moz-transform: rotate(810deg);
    }
  }

  @keyframes cssload-dot-rotate-4 {
    0% {
      transform: rotate(705deg);
    }
    100% {
      transform: rotate(1080deg);
    }
  }

  @-o-keyframes cssload-dot-rotate-4 {
    0% {
      -o-transform: rotate(705deg);
    }
    100% {
      -o-transform: rotate(1080deg);
    }
  }

  @-ms-keyframes cssload-dot-rotate-4 {
    0% {
      -ms-transform: rotate(705deg);
    }
    100% {
      -ms-transform: rotate(1080deg);
    }
  }

  @-webkit-keyframes cssload-dot-rotate-4 {
    0% {
      -webkit-transform: rotate(705deg);
    }
    100% {
      -webkit-transform: rotate(1080deg);
    }
  }

  @-moz-keyframes cssload-dot-rotate-4 {
    0% {
      -moz-transform: rotate(705deg);
    }
    100% {
      -moz-transform: rotate(1080deg);
    }
  }
</style>
