<!-- @format -->

<template>
  <div class="loginpg">
    <div id="bubble-div"></div>

    <!-- form -->
    <div class="form-div sign-in">
      <img src="../img/Meetarts.png" alt="No Image" class="logoimg" />
      <br />
      <br />
      <span class="h1-lbl">Sign In</span>
      <div class="warn" :class="errorclass" v-if="errormsg != ''">
        {{ errormsg }}
      </div>

      <loader-div v-if="loader" :LoaderText="loadertext"></loader-div>
      <input-box
        :Label="'User Name*'"
        v-model="uname"
        :Maxlength="20"
        :InputType="'text'"
        ref="uname"
      />
      <input-box
        :Label="'Password:*'"
        v-model="upass"
        :Maxlength="20"
        :InputType="'password'"
        ref="uname"
      />

      <round-btn
        :BtnClass="'success'"
        :BtnText="'Login'"
        @click="signIn()"
        id="loginbtn"
      ></round-btn>
      <router-link to="signup" class="link">Create an Account</router-link>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import LoaderDiv from "../components/LoaderDiv.vue";
  import InputBox from "../components/InputBox.vue";
  import RoundBtn from "../components/RoundBtn.vue";
  //   import DialogBox from "../components/DialogBox.vue";

  export default {
    name: "Login",
    components: {
      InputBox,
      RoundBtn,
      LoaderDiv,
      //   DialogBox,
    },
    props: {},
    data() {
      return {
        uname: "",
        upass: "",
        loader: false,
        isFormValid: true,
        errormsg: "",
        errorclass: "danger",
      };
    },
    methods: {
      signIn() {
        this.isFormValid = true;
        this.errorclass = "danger";
        this.errormsg = "";
        this.validateform();
        var apiurl = this.$api_url + "appusers.php";
        if (this.isFormValid) {
          this.errormsg = "Checking Please Wait...";
          this.errorclass = "alert";
          axios
            .post(apiurl, {
              optn: "checkuser",
              uname: this.uname,
              upass: this.upass,
            })
            .then((response) => {
              if (response.data.status == true) {
                this.errormsg = "Redirecting...";
                this.errorclass = "success";
                var users = response.data.User;
                localStorage.setItem("uid", users[0].uid);
                localStorage.setItem("uname", users[0].uname);
                localStorage.setItem("ucatg", users[0].ucatg);
                this.$router.replace("/");
              } else {
                this.errormsg = "User / Password Invalid!";
                this.errorclass = "danger";
              }
            });
        } else {
          document.getElementById("uname").focus();
        }
      },
      validateform() {
        if (this.uname == "" || this.upass == "") {
          this.isFormValid = false;
          this.errormsg = "Please Fill User Name/ Password!";
        }
      },
      createBubble(bubbleNo) {
        var winWidth = screen.availWidth;
        var winHeight = screen.availHeight;
        var PosX = 0;
        var PosY = 0;
        var bubblediv = document.getElementById("bubble-div");
        var clrclass = [
          "circle-red",
          "circle-red",
          "circle-red",
          "circle-blue",
          "circle-blue",
          "circle-yellow",
          "circle-yellow",
          "circle-yellow",
          "circle-green",
          "circle-green",
          "circle-green",
        ];
        var dimclass = [
          { width: "50px", height: "50px" },
          { width: "80px", height: "80px" },
          { width: "100px", height: "100px" },
          { width: "120px", height: "120px" },
          { width: "120px", height: "120px" },
          { width: "120px", height: "120px" },
          { width: "150px", height: "150px" },
          { width: "180px", height: "180px" },
          { width: "220px", height: "220px" },
          { width: "100px", height: "100px" },
          { width: "120px", height: "120px" },
          { width: "150px", height: "150px" },
          { width: "50px", height: "50px" },
          { width: "80px", height: "80px" },
          { width: "100px", height: "100px" },
        ];
        var newbubble = document.createElement("div");
        newbubble.id = "bubble" + bubbleNo;
        newbubble.classList.add("bubble");
        var BubbelSetting = {
          getRandomInt(max, min) {
            return Math.round(Math.random() * (max - min) + min, 0);
          },
          colour: function() {
            var clrnumber = this.getRandomInt(0, 11);
            var clr = clrclass[clrnumber];
            return clr;
          },
          size: function() {
            var bubdim = dimclass[this.getRandomInt(0, 14)];
            return bubdim;
          },
        };
        newbubble.classList.add(BubbelSetting.colour());
        var b = BubbelSetting.size();
        newbubble.style.width = b.width;
        newbubble.style.height = b.height;
        PosX = this.getRandomInt((winWidth / 50) * -1, winWidth);
        PosY = this.getRandomInt((winWidth / 50) * -1, winHeight);
        newbubble.style.left = PosX + "px";
        newbubble.style.top = PosY + "px";
        setInterval(() => {
          if (PosX > winWidth || PosX < 0) {
            newbubble.className = "";
            newbubble.classList.add("bubble");
            newbubble.classList.add(BubbelSetting.colour());
            b = BubbelSetting.size();
            newbubble.style.width = b.width;
            newbubble.style.height = b.height;
            PosX = this.getRandomInt((winWidth / 50) * -1, winWidth);
          }
          if (PosY > winHeight || PosY < 0) {
            newbubble.className = "";
            newbubble.classList.add("bubble");
            newbubble.classList.add(BubbelSetting.colour());
            b = BubbelSetting.size();
            newbubble.style.width = b.width;
            newbubble.style.height = b.height;
            PosY = this.getRandomInt((winWidth / 50) * -1, winHeight);
          }
          PosX += this.getRandomInt(-2, 2);
          PosY += this.getRandomInt(-2, 5);

          // PosX += this.getRandomInt(bubbleNo * -1, winWidth);
          // PosY += this.getRandomInt(bubbleNo * -1, winHeight);
          newbubble.style.left = PosX + "px";
          newbubble.style.top = PosY + "px";
        }, 200);

        newbubble.addEventListener("click", function() {
          var bub = document.getElementById(this.id);
          bub.classList.add("brust-bubble");
          setTimeout(() => {
            bub.classList.remove("brust-bubble");
            b = BubbelSetting.size();
            PosX = 0;
            PosY = 0;
            bub.style.width = b.width;
            bub.style.height = b.height;
            bub.style.top = BubbelSetting.getRandomInt(150, winHeight) + "px";
            bub.style.left = BubbelSetting.getRandomInt(20, winWidth) + "px";
          }, 800);
        });
        bubblediv.appendChild(newbubble);
      },
      getRandomInt(max, min) {
        return Math.round(Math.random() * (max - min) + min, 0);
      },
      genBubble() {
        var winWidth = screen.availWidth;
        var bcnt = 10;
        if (winWidth > 700) {
          bcnt = 15;
        }
        if (winWidth > 1024) {
          bcnt = 20;
        }
        if (winWidth > 1300) {
          bcnt = 30;
        }
        for (var i = 1; i <= bcnt; i++) {
          this.createBubble(i);
        }
      },
    },
    mounted() {
      if (localStorage.getItem("uid")) {
        this.$store.commit("checkIsLogin");
        this.$router.replace("/");
      } else {
        this.genBubble();
      }
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .loginpg {
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100vh;
  }
  .form-div {
    position: relative;
    display: block;
    padding: 15px;
    margin: 0px auto !important;
    width: calc(95% - 30px);
    border-radius: 15px;
    border: 1px solid white;
    max-width: 300px;
    box-shadow: 0px 0px 12px 1px lightgray;
    background-color: #ffffff7a;
    backdrop-filter: blur(5px);
  }
  .sign-in {
    position: relative;
    top: 120px;
  }
  .form-control {
    display: block;
    width: calc(100% - 25px);
  }
  .h1-lbl {
    position: relative;
    margin: 0px;
    padding: 0px;
    font-size: 26px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  .warn {
    padding: 4px;
    border-radius: 15px;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  #loginbtn {
    position: relative;
    margin-bottom: 25px;
    font-size: 16px;
    padding: 8px;
  }

  .logoimg {
    position: absolute;
    display: block;
    margin: 0px auto !important;
    top: -45px;
    left: 0px;
    right: 0px;
    width: 90px;
    height: 90px;
  }

  .round-circle {
    position: fixed;
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }

  .round-circle-lg {
    position: fixed;
    width: 220px;
    height: 220px;
    border-radius: 50%;
  }

  .round-circle-sm {
    position: fixed;
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }

  .round-circle-md {
    position: fixed;
    width: 180px;
    height: 180px;
    border-radius: 50%;
  }

  .link {
    position: relative;
    display: block;
    padding: 8px;
    border-radius: 20px;
    margin-bottom: 15px;
    text-decoration: none;
    color: white;
    border: 1px solid var(--primary);
    background-color: var(--primary);
    color: var(--lighttxt);
    box-shadow: 0px 2px 20px 8px var(--primarylight);
  }
</style>
<style>
  .bubble {
    position: fixed;
    border-radius: 50%;
    transition: all ease-in-out 0.3s;
  }
  .circle-red {
    border: 1px solid var(--danger);
    background-color: var(--danger);
    color: var(--lighttxt);
    box-shadow: 0px 2px 20px 8px var(--dangerlight);
  }
  .circle-blue {
    border: 1px solid var(--primary);
    background-color: var(--primary);
    color: var(--lighttxt);
    box-shadow: 0px 2px 20px 8px var(--primarylight);
  }
  .circle-yellow {
    border: 1px solid var(--warning);
    background-color: var(--warning);
    color: var(--lighttxt);
    box-shadow: 0px 2px 20px 8px var(--warninglight);
  }

  .circle-green {
    border: 1px solid var(--success);
    background-color: var(--success);
    color: var(--lighttxt);
    box-shadow: 0px 2px 20px 8px var(--successlight);
  }
  .brust-bubble {
    transition: all ease-in-out 0.8s;
    animation: 0.8s brustbubble;
  }
  @keyframes brustbubble {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }
</style>
