<!-- @format -->

<template>
  <div class="img-selectbox">
    <span
      v-if="imageSrc == ''"
      class="fa fa-camera"
      onclick="document.getElementById('file-input').click();"
    ></span>
    <input type="file" id="file-input" @change="setImg" style="display:none;" />
    <img v-if="imageSrc != ''" :src="imageSrc" alt="" />
  </div>
  <span v-if="imageSrc != ''" class="removeimg" @click="removeImg()"
    >Remove</span
  >
</template>
<script>
  export default {
    emits: ["ImageSelect", "RemoveImage"],
    props: ["ImageData"],
    data() {
      return {
        imageSrc: "",
        removeCalled: false,
      };
    },
    methods: {
      setImg(e) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;
        this.createImage(files[0]);
        this.$emit("ImageSelect", files[0]);
      },
      createImage(file) {
        var reader = new FileReader();
        // var vm = this;
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      },
      removeImg() {
        this.imageSrc = "";
        this.$emit("RemoveImage");
      },
    },
    updated() {
      if (this.ImageData != "") {
        this.imageSrc = this.ImageData;
      }
    },
  };
</script>
<style scoped>
  .img-selectbox {
    display: block;
    cursor: pointer;
    margin: 0px auto !important;
    width: fit-content;
    height: fit-content;
  }
  .img-selectbox .fa-camera {
    border: 1px solid lightgray;
    font-size: 35px;
    padding: 25px;
    border-radius: 50%;
    color: gray;
  }
  .img-selectbox img {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  .removeimg {
    color: var(--danger);
    display: block;
    padding: 0px;
    margin: 0px;
    text-align: center;
    font-weight: bold;
    text-decoration: underline;
    font-size: 14px;
    cursor: pointer;
  }
  .removeimg:hover,
  .removeimg:focus {
    color: var(--dangerdark);
  }
</style>
