<!-- @format -->

<template>
  <nav-bar v-if="this.$store.state.Authenticated"></nav-bar>
  <router-view v-slot="{ Component }">
    <transition name="route" mode="out-in">
      <component :is="Component"></component>
    </transition>
  </router-view>
</template>
<script>
  import NavBar from "./components/NavBar.vue";
  export default {
    components: {
      NavBar,
    },
    data() {
      return {};
    },
  };
</script>
<style>
  :root {
    --white: #ffffff;
    --shadowcolor: #dfdfdf;
    --darkshadowcolor: #c9c7c7;
    --darktxt: #2c3e50;
    --darklighttxt: #50565c;
    --lighttxt: #fff;
    --primary: rgb(26, 92, 255);
    --primarylight: rgb(26, 92, 255, 0.5);
    --primarydark: #173c97;
    --success: rgb(23, 201, 100);
    --successlight: #42b983;
    --successdark: rgb(20, 167, 83);

    --danger: rgb(242, 19, 93);
    --dangerlight: rgba(242, 19, 93, 0.678);
    --dangerdark: rgb(197, 21, 80);
    --warning: rgb(255, 130, 0);
    --warninglight: rgba(255, 132, 0, 0.671);
    --warningdark: rgb(194, 101, 3);
    --dark: rgb(36, 33, 69);
    --darklight: rgb(70, 69, 82);
    --default: #ffffff;
    --defaultlight: #dfdfdf;
    --defaultdark: #c9c7c7;
  }

  html,
  body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    padding: 0px;
    margin: 0px;
    background-color: whitesmoke;
  }

  .toright {
    text-align: right;
  }
  .toleft {
    text-align: left;
  }
  .tocenter {
    text-align: center;
  }

  .round-btn {
    position: relative;
    margin-right: 6px;
    outline: none;
    width: 35px;
    height: 35px;
    border-radius: 25px;
    cursor: pointer;
    margin-bottom: 6px;
    /* background: rgba(var(--primary), 1); */
  }
  .round-btn-sm {
    position: relative;
    margin-right: 6px;
    outline: none;
    width: 100%;
    min-height: 25px;
    border-radius: 25px;
    cursor: pointer;
    margin-bottom: 5px;
    font-size: 12px;
    padding: 8px;
  }

  .primary {
    border: 1px solid var(--primary);
    background-color: var(--primary);
    color: var(--lighttxt);
    box-shadow: 0 2px 4px 0px var(--primarylight);
  }
  .primary:hover {
    background-color: var(--primarydark);
    box-shadow: 0 0px 8px 0px var(--primary);
  }
  .primary:focus {
    background-color: var(--primarydark);
    box-shadow: 0 0px 8px 0px var(--primary);
  }

  .danger {
    border: 1px solid var(--danger);
    background-color: var(--danger);
    color: var(--lighttxt);
    box-shadow: 0 2px 4px 0px var(--dangerlight);
  }
  .danger:hover {
    background-color: var(--dangerdark);
    box-shadow: 0 0px 8px 0px var(--danger);
  }
  .danger:focus {
    background-color: var(--dangerdark);
    box-shadow: 0 0px 8px 0px var(--danger);
  }
  .success {
    border: 1px solid var(--success);
    background-color: var(--success);
    color: var(--lighttxt);
    box-shadow: 0 2px 4px 0px var(--successlight);
  }
  .success:hover {
    background-color: var(--successdark);
    box-shadow: 0 0px 8px 0px var(--success);
  }
  .success:focus {
    background-color: var(--successdark);
    box-shadow: 0 0px 8px 0px var(--success);
  }

  .warning {
    border: 1px solid var(--warning);
    background-color: var(--warning);
    color: var(--lighttxt);
    box-shadow: 0 2px 4px 0px var(--warninglight);
  }
  .warning:hover {
    background-color: var(--warningdark);
    box-shadow: 0 0px 8px 0px var(--warning);
  }
  .warning:focus {
    background-color: var(--warningdark);
    box-shadow: 0 0px 8px 0px var(--warning);
  }

  /* Route */
  .route-enter-from {
    opacity: 0;
    transform: translateX(100px);
  }
  .route-enter-active {
    transition: all 0.3s ease-in-out;
  }

  .route-leave-out {
    opacity: 0;
    transform: translateX(-100px);
  }

  .route-leave-active {
    transition: all 0.3s ease-in;
  }

  .form-title {
    position: relative;
    display: block;
    padding: 5px;
    font-size: 18px;
    font-weight: bolder;
    width: calc(100% - 10px);
    color: var(--dark);
  }
  .title-txt {
    position: relative;
    width: calc(100% - 25px);
    display: inline-block;
    text-decoration: underline;
  }
</style>
