/** @format */

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// const apiurl = "http://localhost:8880/VUE/saleapp/src/php/";
const apiurl = "https://meetarts.in/php/";
const app = createApp(App);
app.use(store);
app.use(router);
app.mount("#app");

app.config.globalProperties.$api_url = apiurl;
