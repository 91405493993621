<!-- @format -->
<template>
  <div class="custmstlist">
    <loader-div v-if="loader" :LoaderText="loadertext"></loader-div>
    <div class="form-title">
      <div class="title-txt">Ledgers</div>
    </div>
    <div class="filters">
      <div class="search-div">
        <input-box
          :TipText="'Search'"
          :InputType="'text'"
          v-model="lookfor"
          @input="searchList()"
        />
      </div>
      <div class="btn-div">
        <button class="round-btn-sm primary" @click="addCust()">Add</button>
      </div>
    </div>
    <div class="cust-table">
      <table>
        <thead>
          <tr>
            <td>{{ "#" + getTotal.Count }}</td>
            <td class="toleft fullname">Customer</td>
            <td class="toleft">Contact No.</td>
            <td class="toright">Balance</td>
            <td class="toleft">E-mail</td>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, index) in tabledata"
            :key="row.glc"
            @click="editRec(row.glc)"
          >
            <td>{{ index + 1 }}</td>
            <td class="toleft fullname">{{ row.gln }}</td>
            <td class="toleft">{{ row.mobno }}</td>
            <td class="toright">{{ row.opnbal }}</td>
            <td class="toleft">{{ row.email }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td></td>
            <td class="toleft fullname">Total</td>
            <td class="toleft">&nbsp;</td>
            <td class="toright">{{ getTotal.Total }}</td>
            <td class="toleft">&nbsp;</td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>
<script>
  import InputBox from "../components/InputBox.vue";
  import axios from "axios";
  import LoaderDiv from "../components/LoaderDiv.vue";
  import MyFun from "../js/MyFun";
  export default {
    components: {
      InputBox,
      LoaderDiv,
    },
    data() {
      return {
        lookfor: "",
        msrno: 0,
        tabledata: [{}],
        loader: false,
        loadertext: "Loading",
        userid: this.$store.state.UserId,
      };
    },
    computed: {
      getTotal: function() {
        var jdata = this.tabledata;
        var tamt = 0;
        for (var i = 0; i < jdata.length; i++) {
          tamt += parseFloat(jdata[i].opnbal);
        }
        return { Total: tamt, Count: jdata.length };
      },
    },
    methods: {
      addCust() {
        var path = "/addcustomer/add/new";
        this.$router.push(path);
      },
      getCustList() {
        var apiurl = this.$api_url + "custmst.php";
        axios
          .post(apiurl, {
            optn: "getlist",
            uid: this.userid,
            mdes: this.lookfor,
          })
          .then((resp) => {
            this.loader = false;
            this.tabledata = resp.data.reclist;
          });
      },
      searchList() {
        setTimeout(() => {
          this.getCustList();
        }, 800);
      },
      editRec(xglc) {
        var path = "/addcustomer/edit/" + MyFun.encrypt(xglc);
        this.$router.push(path);
      },
    },
    beforeMount() {
      this.loader = true;
      this.getCustList();
      // this.loader = false;
    },
  };
</script>
<style scoped>
  .filters {
    position: relative;
    display: block;
    width: 100%;
  }
  .filters div {
    position: relative;
    display: inline-flex;
  }
  .filters .search-div {
    width: calc(100% - 60px);
    background-color: whitesmoke;
    padding: 0px;
    margin: 0px;
  }
  .btn-div button {
    position: relative;
    top: -25px;
  }

  .cust-table {
    display: block;
    width: 100%;
    margin-bottom: 25px;
    max-height: calc(100vh - 150px);
    overflow: auto;
    background-color: whitesmoke;
  }
  .cust-table table {
    width: 100%;
    background-color: var(--white);
    border-spacing: 0px;
  }
  .cust-table table thead {
    position: sticky;
    top: 0px;
    font-weight: bolder;
    background-color: var(--dark);
    padding: 5px;
    color: var(--white);
    box-shadow: 0px 2px 4px 2px var(--darkshadowcolor);
  }
  .cust-table table tfoot {
    position: sticky;
    bottom: 0px;
    font-weight: bolder;
    background-color: var(--dark);
    padding: 5px;
    color: var(--white);
    box-shadow: 2px 0px 4px 2px var(--darkshadowcolor);
  }

  .cust-table table td {
    font-size: 14px;
    padding: 6px;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--darkshadowcolor);
  }
  .cust-table table tbody tr:hover {
    cursor: pointer;
    background-color: var(--darkshadowcolor);
    cursor: pointer;
  }
  .cust-table table .edit-col {
    max-width: 60px;
  }
  .fullname {
    width: 250px;
  }
</style>
