/** @format */

import { createStore } from "vuex";

export default createStore({
  state: {
    UserId: "",
    UserName: "",
    UserFullName: "",
    Authenticated: false,
  },
  mutations: {
    checkIsLogin(state) {
      if (localStorage.getItem("uid")) {
        state.UserId = localStorage.getItem("uid");
        state.UserName = localStorage.getItem("uname");
        state.Authenticated = true;
      } else {
        state.Authenticated = false;
      }
    },
    clearAuth(state) {
      state.Authenticated = false;
      localStorage.removeItem("uid");
      localStorage.removeItem("uname");
      localStorage.removeItem("ucatg");
      localStorage.clear();
    },
  },
  actions: {
    Logout(context) {
      context.commit("clearAuth");
    },
  },
  modules: {},
});
