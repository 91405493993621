<!-- @format -->

<template>
  <div class="loaderdiv">
    <div class="modal-head">
      <slot name="title">Alert</slot>
    </div>
    <div class="modal-body">
      <!-- <img src="../img/ques.png" class="dialogicon" /> -->
      <i class="fa fa-question dialogicon" v-if="dialogtype == 'confirm'"></i>
      <i
        class="fas fa-exclamation-triangle dialogicon"
        v-if="dialogtype == 'warning'"
      ></i>
      <i class="fas fa-info-circle dialogicon" v-if="dialogtype == 'info'"></i>
      <i
        class="far fa-times-circle dialogicon"
        v-if="dialogtype == 'error'"
      ></i>
      <i
        class="far fa-check-circle dialogicon"
        v-if="dialogtype == 'success'"
      ></i>
      <slot name="body"></slot>
    </div>
    <div class="modal-foot">
      <div v-if="dialogtype == 'confirm'">
        <button class="round-btn-sm success" @click="confirm()">Yes</button>
        <button class="round-btn-sm danger" @click="cancel()">No</button>
      </div>
      <div v-else>
        <button class="round-btn-sm primary" @click="cancel()">OK</button>
      </div>
    </div>
  </div>
  <div class="bgdark"></div>
</template>
<script>
  export default {
    emits: ["cancel", "confirm"],
    props: ["dialogtype"],
    methods: {
      confirm() {
        this.$emit("confirm");
      },
      cancel() {
        this.$emit("cancel");
      },
    },
  };
</script>
<style scoped>
  .modal-head {
    display: block;
    padding: 10px;
    text-align: left;
    font-weight: bold;
    border-bottom: 1px solid lightgray;
  }
  .modal-body {
    display: block;
    padding: 15px;
    font-size: 22px;
    max-width: auto;
    min-height: 30px;
    max-height: 250px;
    overflow: auto;
    text-align: left;
    border-bottom: 1px solid lightgray;
  }
  .modal-foot {
    position: sticky;
    display: block;
    bottom: 0px;
    text-align: right;
    padding: 10px;
    height: fit-content;
    border-top: 1px solid lightgray;
  }
  .modal-foot button {
    display: inline-block;
    width: 80px;
  }

  .dialogicon {
    position: relative;
    float: right;
    display: block;
    font-size: 32px;
    margin: 0px;
    padding: 0px;
    /* transition: ease-in-out all 0.5s; */
    animation: iconanimate 0.5s;
  }

  .fa-question {
    color: rgb(57, 128, 235);
  }

  .fa-exclamation-triangle {
    color: #f1b037;
  }

  .fa-info-circle {
    color: #3cbae0;
  }
  .fa-times-circle {
    color: #bd2653;
  }
  .fa-check-circle {
    color: #26bd58;
  }
  .bgdark {
    position: fixed;
    top: 0px;
    width: 100%;
    height: 100vh;
    background-color: #00000049;
    z-index: 99;
  }

  .loaderdiv {
    position: absolute;
    top: 30%;
    margin: 0px auto !important;
    left: 0;
    right: 0;
    width: 300px;
    height: fit-content;
    border-radius: 15px;
    background-color: white;
    z-index: 999;
    animation: fadein 0.5s;
  }
  .loadingtxt {
    position: relative;
    display: block;
    font-size: 24px;
    top: 25px;
    color: white;
  }

  @keyframes iconanimate {
    from {
      transform: rotate(360deg);
    }
    to {
      opacity: 1;
      transform: rotate(0deg);
    }
  }
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
</style>
