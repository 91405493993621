<!-- @format -->

<template>
  <div>
    <loader-div v-if="loader" :LoaderText="loadertext"></loader-div>
    <dialog-box
      v-if="dialog"
      @cancel="toggleDialog('', '', '')"
      :dialogtype="dialogtype"
      @confirm="delRec()"
    />
    <image-box
      @ImageSelect="getImgData"
      @RemoveImage="delImg"
      ref="myImg"
      :ImageData="showImg"
    ></image-box>

    <h1>{{ this.$store.state.UserName }}</h1>
  </div>
</template>

<script>
  import LoaderDiv from "../components/LoaderDiv.vue";
  import DialogBox from "../components/DialogBox.vue";
  export default {
    components: {
      LoaderDiv,
      DialogBox,
    },
    data() {
      return {
        dialog: false,
        dialogtype: "",
        loader: false,
        loadertext: "",
        getImgData: "",
        showImg: false,
      };
    },
    methods: {
      toggleDialog(dlgtype, dlgtitle, dlgbody) {
        this.dialogtype = dlgtype;
        this.dialogtitle = dlgtitle;
        this.dialogbody = dlgbody;
        this.dialog = !this.dialog;
      },
    },
  };
</script>
<style scoped></style>
