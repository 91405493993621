<!-- @format -->

<template>
  <div class="controls">
    <span class="helpmsg">{{ Hint }}</span>

    <input
      v-if="InputType == 'text'"
      type="text"
      class="txt-box"
      :placeholder="TipText"
      :value="modelValue"
      :maxlength="Maxlength"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <input
      v-if="InputType == 'password'"
      type="password"
      class="txt-box"
      :placeholder="TipText"
      :value="modelValue"
      :maxlength="Maxlength"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <input
      v-if="InputType == 'number'"
      type="number"
      class="txt-box"
      :placeholder="TipText"
      :value="modelValue"
      :maxlength="Maxlength"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <input
      v-if="InputType == 'date'"
      type="date"
      class="txt-box"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <input
      v-if="InputType == 'checkbox'"
      type="checkbox"
      :value="modelValue"
      class="chk-box"
      @change="$emit('update:modelValue', $event.target.value)"
    />

    <textarea
      v-if="InputType == 'textarea'"
      type="text"
      class="txtarea-box"
      :placeholder="TipText"
      :value="modelValue"
      :maxlength="Maxlength"
      @input="$emit('update:modelValue', $event.target.value)"
      rows="3"
    ></textarea>
    <label>{{ Label }}</label>
  </div>
</template>
<script lang="ts">
  export default {
    props: ["InputType", "TipText", "Label", "Maxlength", "modelValue", "Hint"],
    data() {
      return {};
    },
  };
</script>

<style scoped>
  .txt-box {
    position: relative;
    display: block;
    width: calc(100% - 32px);
    height: 35px;
    border-radius: 25px;
    padding-left: 12px;
    padding-right: 8px;
    outline: none;
    font-size: 20px;
    margin: 5px;
    margin-bottom: 10px;
    border: 1px solid whitesmoke;
    transition: ease-in-out all 0.3s;
    box-shadow: 0px 1px 2px 0px var(--darkshadowcolor);
  }
  .txt-box:focus {
    box-shadow: 0px 3px 4px 2px var(--darkshadowcolor);
  }
  /* .txt-box:focus::placeholder {
    color: transparent;
  } */

  label {
    position: relative;
    color: var(--dark);
    text-align: left;
    transition: ease-in-out all 0.3s;
  }

  .txt-box:focus + label {
    font-weight: bold;
    margin-left: 8px;
    text-shadow: 1px 1px var(--darkshadowcolor);
  }
  .controls {
    display: flex;
    flex-direction: column-reverse;
  }

  .txtarea-box {
    position: relative;
    display: block;
    width: calc(100% - 32px);
    border-radius: 15px;
    padding-left: 12px;
    padding-top: 8px;
    padding-right: 8px;
    outline: none;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    margin: 5px;
    margin-bottom: 10px;
    border: 1px solid whitesmoke;
    transition: ease-in-out all 0.3s;
    box-shadow: 0px 1px 2px 0px var(--darkshadowcolor);
  }
  .txtarea-box:focus {
    box-shadow: 0px 3px 4px 2px var(--darkshadowcolor);
  }

  .txtarea-box:focus + label {
    font-weight: bold;
    margin-left: 8px;
    text-shadow: 1px 1px var(--darkshadowcolor);
  }
  .helpmsg {
    position: relative;
    margin: 0px;
    padding: 0px;
    top: -8px;
    left: 10px;
    font-size: 10px;
    text-align: left;
    font-style: italic;
    color: gray;
  }
</style>
