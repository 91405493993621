<!-- @format -->

<template>
  <div>
    <div class="form-div" v-show="mainform">
      <loader-div v-if="loader" :LoaderText="loadertext"></loader-div>
      <dialog-box
        v-if="dialog"
        @cancel="toggleDialog('', '', '')"
        :dialogtype="dialogtype"
        @confirm="delRec()"
      >
        <template v-slot:title>
          {{ dialogtitle }}
        </template>
        <template v-slot:body>
          {{ dialogbody }}
        </template>
      </dialog-box>
      <div class="form-title">
        <div class="title-txt">Expense ({{ EntryType }})</div>
        <i
          v-if="Optn == 'edit'"
          class="fa fa-trash danger-icon"
          data-toggle="tooltip"
          title="Delete"
          @click="toggleDialog('confirm', 'Delete', 'Do you want to Delete?')"
        ></i>
      </div>
      <div class="form-detail">
        <input-box
          :Label="'Date*'"
          v-model="docdt"
          :InputType="'date'"
          ref="docdt"
        />
        <input-box
          :Label="'Description*'"
          v-model="des"
          :Maxlength="50"
          :InputType="'text'"
          ref="catgdes"
        />
        <cmb-box
          :Label="'Category Type*'"
          :CmbData="cmbdata"
          v-model="catgid"
          :ShowAddBtn="true"
          @add-btn="addCatg()"
        ></cmb-box>
        <div class="chkbox-div">
          <label class="label" for="payable">Receiveable</label>
          <input
            type="checkbox"
            class="chk-box"
            id="payable"
            v-model="payable"
          />
        </div>
        <cmb-box
          v-if="payable == true"
          :Label="'Customer Name*'"
          :CmbData="custdata"
          v-model="glc"
          :ShowAddBtn="true"
          @add-btn="addCust()"
        ></cmb-box>
        <input-box :Label="'Amount*'" v-model="amount" :InputType="'number'" />
        <round-btn
          :BtnClass="'success'"
          :BtnText="'Save'"
          @click="saveForm()"
        ></round-btn>
      </div>
    </div>
    <catg-mst
      v-show="catgmst"
      :subform="true"
      :moptn="'add'"
      @cancel-btn="addCatg()"
    ></catg-mst>
  </div>
</template>

<script>
  import axios from "axios";
  import LoaderDiv from "../components/LoaderDiv.vue";
  import InputBox from "../components/InputBox.vue";
  import RoundBtn from "../components/RoundBtn.vue";
  import DialogBox from "../components/DialogBox.vue";
  import CmbBox from "../components/CmbBox.vue";
  import CatgMst from "./CatgMst.vue";
  import MyFun from "../js/MyFun";

  export default {
    components: {
      InputBox,
      RoundBtn,
      LoaderDiv,
      DialogBox,
      CmbBox,
      CatgMst,
    },
    props: ["mtrackno", "moptn"],
    data() {
      return {
        Optn: this.moptn,
        mainform: true,
        catgmst: false,
        des: "",
        catgid: "",
        docdt: "",
        amount: "",
        EntryType: "",
        loader: false,
        loadertext: "",
        dialog: false,
        userid: this.$store.state.UserId,
        dialogtype: "",
        dialogtitle: "",
        payable: false,
        glc: "",
        pflag: "",
        dialogbody: "",
        datapage: "expent.php",
        custdata: [
          {
            name: "--Select--",
            val: "",
          },
        ],
        cmbdata: [
          {
            name: "--Select--",
            val: "",
          },
        ],
      };
    },
    methods: {
      saveForm() {
        if (this.isFormVaild()) {
          this.loader = true;
          this.loadertext = "Saving";
          var apiurl = this.$api_url + this.datapage;
          axios
            .post(apiurl, {
              optn: this.moptn,
              uid: this.userid,
              des: this.des,
              catgid: this.catgid,
              docdt: this.docdt,
              amount: this.amount,
              glc: this.glc,
              pflag: this.pflag,
              trackno: this.trackno,
            })
            .then((resp) => {
              this.loader = false;
              if (resp.data.Error == false) {
                this.toggleDialog("success", "Saved", "Record is Saved!");
                if (this.moptn == "add") {
                  this.fablank();
                } else {
                  this.$router.replace("/explist");
                }
              } else {
                this.toggleDialog("error", "Error", resp.data);
              }
            });
        } else {
          this.toggleDialog(
            "warning",
            "No Info. to Save!",
            "Please fill * marked boxes before saving!"
          );
        }
      },
      fablank() {
        this.Optn = this.moptn;
        this.des = "";
        this.catgid = "";
        this.amount = "";
        this.docdt = this.getCurdt();
        this.EntryType = "Add";
        this.glc = "";
        this.payable = false;
        this.focusCust();
      },
      getRec() {
        this.loader = true;
        this.loadertext = "Loading";
        var apiurl = this.$api_url + this.datapage;
        axios
          .post(apiurl, {
            optn: "getrec",
            uid: this.userid,
            trackno: this.trackno,
          })
          .then((resp) => {
            this.loader = false;
            if (resp.data.rows.length > 0) {
              var recdata = resp.data.rows[0];
              this.catgid = recdata.catgid;
              this.docdt = recdata.docdt;
              this.des = recdata.des;
              this.amount = recdata.amount;
              this.glc = recdata.glc;
              if (recdata.pflag == "Y") {
                this.payable = true;
              } else {
                this.payable = false;
              }
            } else {
              this.toggleDialog("error", "Error", "Record Not Found!");
              this.$router.replace("/explist");
            }
          });
      },
      toggleDialog(dlgtype, dlgtitle, dlgbody) {
        this.dialogtype = dlgtype;
        this.dialogtitle = dlgtitle;
        this.dialogbody = dlgbody;
        this.dialog = !this.dialog;
      },
      delRec() {
        this.Dialog = true;
        this.loader = true;
        this.loadertext = "Deleting";
        var apiurl = this.$api_url + this.datapage;
        axios
          .post(apiurl, {
            optn: "delete",
            uid: this.userid,
            trackno: this.trackno,
          })
          .then((resp) => {
            this.toggleDialog("", "", "");
            if (resp.data.Error == false) {
              this.loader = false;
              this.$router.replace("/explist");
            } else {
              this.toggleDialog("error", "Error", resp.data);
            }
          });
      },
      focusCust() {
        this.$refs.catgdes.$el.children[0].focus();
      },
      isFormVaild() {
        var formvalid = true;
        if (
          this.docdt == "" ||
          this.des == "" ||
          this.catgid == "" ||
          this.catgid == "0" ||
          this.amount == 0 ||
          this.amount == null
        ) {
          formvalid = false;
        } else {
          if (this.payable == true) {
            if (this.glc == "") {
              formvalid = false;
            }
          }
        }
        if (this.payable) {
          this.pflag = "Y";
        } else {
          this.pflag = "N";
        }
        return formvalid;
      },
      getCurdt() {
        var date = new Date();
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        if (month < 10) month = "0" + month;
        if (day < 10) day = "0" + day;
        var today = year + "-" + month + "-" + day;
        console.log(today);
        return today.trim();
      },
      getCatgData() {
        this.cmbdata = [
          {
            name: "--Select--",
            val: "",
          },
        ];
        var apiurl = this.$api_url + "catgmst.php";
        axios
          .post(apiurl, {
            optn: "getlist",
            uid: this.userid,
            mdes: "",
          })
          .then((resp) => {
            if (resp.data.Error == false) {
              this.loader = false;
              var resdata = resp.data.reclist;
              for (var i = 0; i < resdata.length; i++) {
                if (resdata[i].ctgtype == "E") {
                  var arr = {
                    name: "",
                    val: "",
                  };
                  arr.name = resdata[i].catgdes;
                  arr.val = resdata[i].catgid;
                  this.cmbdata.push(arr);
                }
              }
            } else {
              console.log(resp.data);
            }
          });
      },
      getCustData() {
        var apiurl = this.$api_url + "custmst.php";
        axios
          .post(apiurl, {
            optn: "getlist",
            uid: this.userid,
            mdes: "",
          })
          .then((resp) => {
            if (resp.data.Error == false) {
              this.loader = false;
              var resdata = resp.data.reclist;
              for (var i = 0; i < resdata.length; i++) {
                var arr = {
                  name: "",
                  val: "",
                };
                arr.name = resdata[i].gln;
                arr.val = resdata[i].glc;
                this.custdata.push(arr);
              }
            } else {
              console.log(resp.data);
            }
          });
      },
      addCatg() {
        this.catgmst = !this.catgmst;
        this.mainform = !this.mainform;
        if (this.mainform == true) {
          this.getCatgData();
        }

        // this.$router.push("/catg/add/new");
      },
      addCust() {
        this.$router.push("/addcustomer/add/new");
      },
    },
    created() {
      if (this.moptn == "edit") {
        this.EntryType = "Modify";
        this.trackno = MyFun.decrypt(this.mtrackno);
        this.getRec();
      } else {
        this.EntryType = "Add";
      }
    },
    mounted() {
      this.focusCust();
      this.fablank();
      this.getCatgData();
      this.getCustData();
    },
  };
</script>

<style scoped>
  .form-div {
    position: relative;
    display: block;
    margin: 0px;
    padding: 0px;
  }
  .form-title {
    position: relative;
    display: block;
    padding: 5px;
    width: calc(100% - 10px);
    background-color: var(--dark);
    color: var(--white);
  }
  .title-txt {
    position: relative;
    width: calc(100% - 25px);
    display: inline-block;
  }
  .form-detail {
    display: block;
    padding: 5px;
    width: calc(100% - 10px);
    background-color: var(--white);
    background-color: whitesmoke;
  }

  .danger-icon {
    position: relative;
    font-size: 20px;
    right: 15px;
    color: var(--danger);
    cursor: pointer;
  }
  .danger-icon:hover {
    color: var(--white);
    text-shadow: 2px 0px 6px var(--danger);
  }
  .chkbox-div {
    position: relative;
    display: block;
    text-align: left;
    padding: 5px;
  }
  .chk-box {
    position: relative;
    width: 20px;
    height: 20px;
    margin: 8px;
    margin-left: 15px;
  }
  .chk-box:focus {
    box-shadow: 0px 3px 4px 2px var(--darkshadowcolor);
  }
  .label {
    position: relative;
    top: -5px;
    margin-left: 0px;
    font-weight: bold;
  }
  .label:focus {
    text-shadow: 1px 1px var(--darkshadowcolor);
  }
</style>
