<!-- @format -->
<template>
  <div class="mstlist">
    <loader-div v-if="loader" :LoaderText="loadertext"></loader-div>
    <div class="form-title">
      <div class="title-txt">Expenses/Payments</div>
    </div>

    <div class="filters">
      <div class="search-div">
        <input-box
          :TipText="'Search'"
          :InputType="'text'"
          v-model="lookfor"
          @input="searchList()"
        />
      </div>
      <div class="btn-div">
        <button class="round-btn-sm primary" @click="addBtn()">Add</button>
      </div>
    </div>
    <div class="mst-table">
      <table id="exptable">
        <thead>
          <tr>
            <td>{{ "#" + getTotal.Count }}</td>
            <td class="toleft">Date</td>
            <td class="toleft fullname">Description</td>
            <td class="toright">Amount</td>
            <td class="toleft">Category</td>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, index) in tabledata"
            :key="row.trackno"
            @click="editRec(row.trackno)"
          >
            <td>{{ index + 1 }}</td>
            <td class="toleft">
              <div class="dttable">
                <div class="row">
                  <div class="col colspan-2">
                    <span class="dt">{{ row.docdt.dt }}</span>
                  </div>
                  <div class="col">
                    <span class="mthyr">{{
                      row.docdt.mth + " " + row.docdt.yr
                    }}</span>
                    <span class="day" :class="row.docdt.weekend">{{
                      row.docdt.day
                    }}</span>
                  </div>
                </div>
              </div>
            </td>
            <td class="toleft fullname">
              {{ row.des }} <span class="gln">{{ row.gln }}</span>
            </td>
            <td class="toright">{{ row.amount }}</td>
            <td class="toleft">
              {{ row.catgdes }}
              <span class="payable">{{
                row.pflag == "Y" ? "Receiveable" : ""
              }}</span>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="2"></td>
            <td class="toright">Total</td>
            <td class="toright">{{ Math.round(getTotal.Total, 2) }}</td>
            <td>&nbsp;</td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>
<script>
  import InputBox from "../components/InputBox.vue";
  import axios from "axios";
  import LoaderDiv from "../components/LoaderDiv.vue";
  import MyFun from "../js/MyFun";
  export default {
    components: {
      InputBox,
      LoaderDiv,
    },
    data() {
      return {
        lookfor: "",
        msrno: 0,
        tabledata: [{}],
        loader: false,
        loadertext: "Loading",
        userid: this.$store.state.UserId,
        datapage: "expent.php",
      };
    },
    computed: {
      getTotal: function() {
        var jdata = this.tabledata;
        var tamt = 0;
        for (var i = 0; i < jdata.length; i++) {
          var mdt = {
            dt: "",
            mth: "",
            yr: "",
            day: "",
            weekend: "",
          };
          var mdocdt = jdata[i].docdt;
          if (mdocdt != undefined) {
            const getmth = new Date(mdocdt);
            mdt.dt = mdocdt.substr(8, 2);
            // mdt.mth = mdocdt.substr(5, 2);
            mdt.mth = getmth.toLocaleString("default", { month: "short" });
            mdt.yr = mdocdt.substr(2, 2);
            mdt.day = getmth.toLocaleString("locale", { weekday: "short" });
            if (mdt.day == "Sat" || mdt.day == "Sun") {
              mdt.weekend = "weekend";
            }
          }
          jdata[i].docdt = mdt;
          tamt += parseFloat(jdata[i].amount);
        }
        return { Total: tamt, Count: jdata.length };
      },
    },
    methods: {
      addBtn() {
        var path = "/exp/add/new";
        this.$router.push(path);
      },
      getRecList() {
        var apiurl = this.$api_url + this.datapage;
        axios
          .post(apiurl, {
            optn: "getlist",
            uid: this.userid,
            mdes: this.lookfor,
          })
          .then((resp) => {
            console.log(resp);
            if (resp.data.Error == false) {
              this.loader = false;
              this.tabledata = resp.data.reclist;
            } else {
              this.loader = false;
              console.log(resp.data);
            }
          });
      },
      searchList() {
        setTimeout(() => {
          // this.loader = true;
          this.getRecList();
        }, 800);
      },
      editRec(xtrackno) {
        var path = "/exp/edit/" + MyFun.encrypt(xtrackno.toString());
        this.$router.push(path);
      },
    },
    beforeMount() {
      this.loader = true;
      this.getRecList();
    },
  };
</script>
<style scoped>
  .filters {
    position: relative;
    display: block;
    width: 100%;
  }
  .filters div {
    position: relative;
    display: inline-flex;
  }
  .filters .search-div {
    width: calc(100% - 60px);
    background-color: whitesmoke;
    padding: 0px;
    margin: 0px;
  }
  .btn-div button {
    position: relative;
    top: -25px;
  }
  .mst-table {
    display: block;
    width: 100%;
    margin-bottom: 25px;
    max-height: calc(100vh - 150px);
    overflow: auto;
    background-color: whitesmoke;
  }
  .mst-table #exptable {
    width: 100%;
    background-color: var(--white);
    border-spacing: 0px;
  }
  .mst-table #exptable thead {
    position: sticky;
    top: 0px;
    font-weight: bolder;
    background-color: var(--dark);
    padding: 5px;
    color: var(--white);
    z-index: 99;
    box-shadow: 0px 2px 4px 2px var(--darkshadowcolor);
  }
  .mst-table #exptable tfoot {
    position: sticky;
    bottom: 0px;
    font-weight: bolder;
    background-color: var(--dark);
    padding: 5px;
    color: var(--white);
    box-shadow: 2px 0px 4px 2px var(--darkshadowcolor);
  }

  .mst-table #exptable td {
    font-size: 14px;
    padding: 6px;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--darkshadowcolor);
  }
  .mst-table #exptable tbody tr:hover {
    cursor: pointer;
    background-color: var(--darkshadowcolor);
    cursor: pointer;
  }
  .mst-table #exptable .edit-col {
    max-width: 60px;
  }
  .fullname {
    width: 250px;
  }
  .gln {
    position: relative;
    display: block;
    font-size: 9px;
    font-weight: bold;
    font-style: italic;
  }
  .payable {
    position: relative;
    display: block;
    font-size: 9px;
    font-weight: bold;
    font-style: italic;
    color: brown;
  }

  .dttable {
    position: relative;
    display: block;
    margin: 0px;
    padding: 0px;
    min-width: 50px;
  }

  .dttable .col {
    position: relative;
    margin: 0px;
    padding: 0px;
  }

  .dttable .row .col {
    display: inline-block;
  }

  .dttable .row .col span {
    position: relative;
    text-align: left;
    display: block;
  }

  .dt {
    position: relative;
    font-size: 16px;
    font-weight: bold;
    margin: 2px;
  }
  .mthyr {
    position: relative;
    margin: 0px;
    top: 0px;
    font-size: 10px;
    font-weight: bold;
  }
  .day {
    position: relative;
    padding: 2px;
    padding-left: 6px;
    padding-right: 6px;
    font-weight: bold;
    text-align: center;
    margin: 2px;
    font-size: 10px;
    border-radius: 8px;
    border: 1px solid lightgray;
  }
  .weekend {
    border: 0px solid lightgray;
    background-color: var(--warning);
    color: white;
  }
</style>
