<!-- @format -->

<template>
  <div class="form-div">
    <loader-div v-if="loader" :LoaderText="loadertext"></loader-div>
    <dialog-box
      v-if="dialog"
      @cancel="toggleDialog('', '', '')"
      :dialogtype="dialogtype"
      @confirm="delRec()"
    >
      <template v-slot:title>
        {{ dialogtitle }}
      </template>
      <template v-slot:body>
        {{ dialogbody }}
      </template>
    </dialog-box>
    <div class="form-title">
      <div class="title-txt">Category ({{ EntryType }})</div>
      <i
        v-if="Optn == 'edit'"
        class="fa fa-trash danger-icon"
        data-toggle="tooltip"
        title="Delete"
        @click="toggleDialog('confirm', 'Delete', 'Do you want to Delete?')"
      ></i>
    </div>
    <div class="form-detail">
      <input-box
        :Label="'Category Name*'"
        v-model="catgdes"
        :Maxlength="50"
        :InputType="'text'"
        ref="catgdes"
      />
      <cmb-box
        :Label="'Category Type*'"
        :CmbData="cmbdata"
        v-model="ctgtype"
      ></cmb-box>
      <round-btn
        :BtnClass="'success'"
        :BtnText="'Save'"
        @click="saveForm()"
      ></round-btn>
      <round-btn
        v-if="subform == true"
        :BtnClass="'danger'"
        :BtnText="'Cancel'"
        @click="cancel()"
      ></round-btn>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import LoaderDiv from "../components/LoaderDiv.vue";
  import InputBox from "../components/InputBox.vue";
  import RoundBtn from "../components/RoundBtn.vue";
  import DialogBox from "../components/DialogBox.vue";
  import CmbBox from "../components/CmbBox.vue";
  import MyFun from "../js/MyFun";

  export default {
    components: {
      InputBox,
      RoundBtn,
      LoaderDiv,
      DialogBox,
      CmbBox,
    },
    emits: ["cancel-btn"],
    props: ["mcatgid", "moptn", "subform"],
    data() {
      return {
        Optn: this.moptn,
        catgdes: "",
        catgid: "",
        ctgtype: "",
        EntryType: "",
        loader: false,
        loadertext: "",
        dialog: false,
        userid: this.$store.state.UserId,
        dialogtype: "",
        dialogtitle: "",
        dialogbody: "",
        datapage: "catgmst.php",
        cmbdata: [
          {
            name: "--Select--",
            val: "",
          },
          {
            name: "Expense",
            val: "E",
          },
          {
            name: "Income",
            val: "I",
          },
        ],
      };
    },
    methods: {
      saveForm() {
        if (this.isFormVaild()) {
          this.loader = true;
          this.loadertext = "Saving";
          var apiurl = this.$api_url + this.datapage;
          console.log(this.userid);
          console.log(this.moptn);
          console.log(this.datapage);
          axios
            .post(apiurl, {
              optn: this.moptn,
              uid: this.userid,
              catgdes: this.catgdes,
              catgid: this.catgid,
              ctgtype: this.ctgtype,
            })
            .then((resp) => {
              console.log(resp);
              this.loader = false;
              if (resp.data.Error == false) {
                this.toggleDialog("success", "Saved", "Record is Saved!");
                if (this.moptn == "add") {
                  this.fablank();
                } else {
                  this.$router.replace("/catglist");
                }
              } else {
                this.toggleDialog("error", "Error", resp.data);
              }
            });
        } else {
          this.toggleDialog(
            "warning",
            "No Info. to Save!",
            "Please fill * marked boxes before saving!"
          );
        }
      },
      fablank() {
        this.Optn = this.moptn;
        this.catgdes = "";
        this.catgid = "";
        this.ctgtype = "";
        this.EntryType = "Add";
        this.focusCust();
      },
      getRec() {
        this.loader = true;
        this.loadertext = "Loading";
        var apiurl = this.$api_url + this.datapage;
        axios
          .post(apiurl, {
            optn: "getrec",
            uid: this.userid,
            catgid: this.catgid,
          })
          .then((resp) => {
            this.loader = false;
            if (resp.data.cust.length > 0) {
              var custdet = resp.data.cust[0];
              this.catgid = custdet.catgid;
              this.catgdes = custdet.catgdes;
              this.ctgtype = custdet.ctgtype;
            } else {
              this.toggleDialog("error", "Error", "Record Not Found!");
              this.$router.replace("/catglist");
            }
          });
      },
      toggleDialog(dlgtype, dlgtitle, dlgbody) {
        this.dialogtype = dlgtype;
        this.dialogtitle = dlgtitle;
        this.dialogbody = dlgbody;
        this.dialog = !this.dialog;
      },
      delRec() {
        this.Dialog = true;
        this.loader = true;
        this.loadertext = "Deleting";
        var apiurl = this.$api_url + this.datapage;
        axios
          .post(apiurl, {
            optn: "delete",
            uid: this.userid,
            catgid: this.catgid,
          })
          .then((resp) => {
            console.log(resp);
            this.toggleDialog("", "", "");
            if (resp.data.Error == false) {
              this.loader = false;
              this.$router.replace("/catglist");
            } else {
              this.loader = false;
              this.toggleDialog("warning", "Alert", resp.data.ErrorMsg);
            }
          });
      },
      focusCust() {
        this.$refs.catgdes.$el.children[0].focus();
      },
      isFormVaild() {
        var formvalid = true;
        if (this.catgdes == "" || this.ctgtype == "") {
          formvalid = false;
        }
        return formvalid;
      },
      cancel() {
        this.$emit("cancel-btn");
      },
    },
    created() {
      if (this.moptn == "edit") {
        this.EntryType = "Modify";
        this.catgid = MyFun.decrypt(this.mcatgid);
        this.getRec();
      } else {
        this.EntryType = "Add";
      }
    },
    mounted() {
      this.focusCust();
    },
  };
</script>

<style scoped>
  .form-div {
    position: relative;
    display: block;
    margin: 0px;
    padding: 0px;
  }
  .form-title {
    position: relative;
    display: block;
    padding: 5px;
    width: calc(100% - 10px);
    background-color: var(--dark);
    color: var(--white);
  }
  .title-txt {
    position: relative;
    width: calc(100% - 25px);
    display: inline-block;
  }
  .form-detail {
    display: block;
    padding: 5px;
    width: calc(100% - 10px);
    background-color: var(--white);
    background-color: whitesmoke;
  }

  .danger-icon {
    position: relative;
    font-size: 20px;
    right: 15px;
    color: var(--danger);
    cursor: pointer;
  }
  .danger-icon:hover {
    color: var(--white);
    text-shadow: 2px 0px 6px var(--danger);
  }
</style>
