/** @format */

import Home from "../views/Home.vue";
import CustMstList from "../views/CustMstList.vue";
import CustMst from "../views/CustMst.vue";
import CatgMstList from "../views/CatgMstList.vue";
import CatgMst from "../views/CatgMst.vue";
import Expense from "../views/Expense.vue";
import ExpenseList from "../views/ExpenseList.vue";
import Income from "../views/Income.vue";
import IncomeList from "../views/IncomeList.vue";
import Login from "../views/Login.vue";
import SignUp from "../views/SignUp.vue";
import NotFound from "../views/NotFound.vue";
import Profile from "../views/Profile.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/signup",
    name: "SignUp",
    component: SignUp,
  },
  {
    path: "/customer",
    name: "Customer",
    component: CustMstList,
  },
  {
    path: "/addcustomer/:moptn/:mglc",
    name: "AddCustomer",
    component: CustMst,
    props: true,
  },
  {
    path: "/catglist",
    name: "CategoryList",
    component: CatgMstList,
  },
  {
    path: "/catg/:moptn/:mcatgid",
    name: "Category",
    component: CatgMst,
    props: true,
  },
  {
    path: "/explist",
    name: "ExpenseList",
    component: ExpenseList,
  },
  {
    path: "/exp/:moptn/:mtrackno",
    name: "Expense",
    component: Expense,
    props: true,
  },
  {
    path: "/inclist",
    name: "IncomeList",
    component: IncomeList,
  },
  {
    path: "/inc/:moptn/:mtrackno",
    name: "Income",
    component: Income,
    props: true,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/.*",
    name: "NotFound",
    component: NotFound,
  },
];

export default routes;
