/** @format */

import { createRouter, createWebHashHistory } from "vue-router";
import routes from "./routes.js";
import store from "../store/index.js";

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to) => {
  store.commit("checkIsLogin");
  if (store.state.Authenticated == true) {
    if (to.name == "Login" || to.name == "SignUp") {
      router.push("/");
    }
  } else {
    if (to.name != "Login" && to.name != "SignUp") {
      router.push("/login");
    }
  }

  // if (to.name != "/" && to.name != "SignUp") {
  //   if (store.state.Authenticated) {
  //     next();
  //   }
  // } else {
  //   if (to.name == "Home" || to.name == "SignUp") {
  //     if (store.state.Authenticated) {
  //       router.replace("/");
  //     } else {
  //       next();
  //     }
  //   }
  // }
});
export default router;
